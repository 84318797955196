@charset "UTF-8";

@import "../../utilities/variables";
@import "../../utilities/mixins";
@import "../../support/layout";
@import "../../support/board-list";
@import "../../support/board-view";
@import "../../support/form-field";
@import "../../support/form-result";
@import "../../support/modal";
@import "../../support/process";
@import "../../support/banner";
@import "../../support/quick-menu";
@import "../../support/selectModel";
@import "../../support/mcustom-scrollbar";
@import "../../support/pages/payNwarranty";

/* 삭제 예정 */
label img {
    pointer-events: none;
}
/* //삭제 예정 */

.a-link {
    text-decoration: underline;
}
.underline {
    text-decoration: underline;
}

// BTOCSITE-18112 웹 접근성 관련 수정 요청
// BTOCSITE-27131 페이지네이션 UI 수정요청
// BTOCSITE-71268 고객지원 > 웹접근성
// .blind, caption, legend {
//     //BTOCSITE-71268 고객지원 > 웹접근성
//     // font-size: 0 !important;
//     opacity: 0;
//     top: -9999px;
//     text-indent: 100%;
// }
/* 재정의: form */
/* input */
// .input-wrap {
//     input, textarea {
//         border-color:#929292;
//         &::-webkit-input-placeholder,
//         &:-moz-placeholder,
//         &:-ms-input-placeholder {
//             color:#666;
//         }
//         &::placeholder {
//             color:#666;
//             opacity:1;
//         }
    
//         &:focus {
//             outline:none;
//             border-color:#000;
//         }
        
//         &:read-only {
//             background:#eee;
//             border-color:#8F8F8F;
//             color:#666;
//         }
    
//         &:disabled,
//         &.disabled {
//             background:#eee;
//             border-color:#8F8F8F;
//             color:#666;
//         }
//     }
//     input[readonly=''], textarea[readonly=''] {
//         background: #eee;
//         border-color: #8f8f8f;
//         &::-webkit-input-placeholder,
//         &:-moz-placeholder,
//         &:-ms-input-placeholder {
//             color:#666;
//         }
//         &::placeholder {
//             color: #666;
//         }
//     }
// }
/* textarea */
.text-form {
    textarea + .inner-text {
        border-color:#8f8f8f;
    }
    // BTOCSITE-82900 고객지원 > 메인 리뉴얼 > 서비스 재접수
    textarea[disabled=''] + .inner-text {
        border-color: #8f8f8f;
    }
}
/* radio */
.rdo-wrap {
    input {
        &:checked + label:after {
            background-image:url(/lg5-common/images/CS/icon/ic_radio_on_24.svg);
        }        
    }
} 
.thumb-rdo-wrap {
    label {
        input[type=radio] {
            &:checked ~ .txt:before {
                background: url(/lg5-common/images/CS/icon/ic_radio_on_24.svg) no-repeat 50% 50% / 23px;
            }
            &:checked ~ .txt:after {
                background:#000;
            }
        }
        /* 재정의: img */
        .img {
            border-radius:24px;
        }
    }
}

.rdo-list-wrap {
    &.w-auto, &.thumb-type {
        .rdo-list {
            li{
                &.block {
                    display:flex;
                    align-items:center;
                    .rdo-wrap {
                        ~ .input-wrap {
                            margin-top:0;
                            margin-left:6px;
                        }
                    }
                }
            }
        }
    }
    &.thumb-type.position {
        .rdo-list {
            li:first-child {
                margin-right:0;
                @include mobile {
                    margin-right:50%;
                }
            }
        }
    }
}
// .form-wrap .forms .conts .phoneWrap {
//     display:flex;
//     justify-content: space-between;
//     align-items:center;
//     .input-wrap {
//         width:calc((100% / 3) - 10px);
//         margin:0;
//     }
//     .dash {
//         font-size:14px;
//         color:#666;
//     }
// }

.container .cont-wrap .section .tit-wrap .tit {
    padding-bottom:8px;
    font-size:16px;
    .point {
        color:#EA1917;
    }
}
/* 재정의: button */
/* button 재 정의 */
.btn{
    padding:14px 25px;
    background:#ea1917;
    border:1px solid #ea1917;
    font-size:14px;
    line-height:20px;
    &.size {
        padding:6px 16px;
        font-size:12px;
        line-height:18px;
        &.mix {
            padding:7px 16px;
        }
    }
    &.size-m {
        min-width:86px;
        padding:9px 16px;
        line-height:20px;
    }
    &.size-l {
        min-width:102px;
        padding:12px 16px;
        line-height:20px;
    }
    &.size-xl {
        min-width:102px;
        padding:14px 16px;
        line-height:20px;
    }
    &.square {
        border-radius:6px !important;
        &.border {
            background:none;
            color:#222;
            border:1px solid #222;
        }
        &.plus {
            position:relative;
            padding-right:30px;
            &:after {
                content: '';
                position:absolute;
                right:12px;
                top:50%;
                width:16px;
                height:16px;
                transform: translateY(-50%);
                background: url(/lg5-common/images/CS/icon/icon_plus_white_16.svg) center no-repeat;
                background-size:16px 16px;
            }
        }
    }
    &.border {
        &.pink {
            color:#ea1917;
            &:hover {
                border-color: #ea1917;
                background: inherit !important;
                color: #ea1917;
            }
        }    
    }
}

.btn-link {
    &.non-underline {
        text-decoration:none;
    }
}

.form-wrap {
    .forms {
        .btn {
            min-width:103px;
            font-size:14px;
            line-height:20px;
        }
        .conts {
            .prod-search-wrap {
                padding:0;
            }   
            .phoneWrap {
                display:flex;
                justify-content: space-between;
                align-items:center;
                .input-wrap {
                    width:calc((100% / 3) - 10px);
                    margin:0;
                }
                .dash {
                    font-size:14px;
                    color:#666;
                }
            }
        }
        .box {
            >.input-wrap { 
            }
            .cell {
                padding-left:8px;
                &.mark {
                    width: 25px;
                    margin:0;
                    padding: 0;
                    font-size:14px;
                    text-align: center;
                    color: #666;
                    vertical-align: middle;
                }
                &.email-addr {
                    width:40%;
                    padding-left:0;
                    .prod-search-wrap {
                        .form-wrap {
                            .forms {
                                .conts {
                                    margin-top:0;
                                }
                            }
                        }
                    }
                    > .input-wrap {
                        margin-bottom:8px;
                    }
                    > .select-wrap {
                        margin-top:0;
                    }
                }
                
            }
            
        }
        // 개선해주세요 email
        .email-wrap {
            &.no-mark {
                .mark {
                    display: none;
                }
                .email-addr {
                    padding-left: 10px;
                }
            }
        }
        .input-wrap {
            margin-top:8px;
        }
    }
    @media screen and (min-width:768px) {
        .forms {
            .tit {
                width:100%;
            }
            .conts {
                width:100%;
                margin-top:12px;
            }
        }
    }
}


// 출장예약페이지 - gnb 돋보기 장바구니 비노출, home 노출
.cs-service .header .utility .shortcut > li.search,
.cs-service .header .utility .shortcut > li.cart {
    display:none;
    @include pc {
        display:inline-block;
    }
}
.cs-service .header .utility .shortcut > li.home {
    display:inline-block;
    @include pc {
        display:none;
    }
}

.cs-service ~ .mobile-status-bar, .cs-service .mobile-status-bar, .cs-service footer, .cs-service ~ footer {
    display:none !important; 
}
.is-backBtn-hide {
    .mobile-nav-wrap {
        .btn-back {
            display:none !important;
        }
    }
}

.container {
    .cont-narrow {
        max-width:1024px;
        margin: 0 auto;
    }
    // BTOCSITE-20648 고객지원 일부 화면 변경된 GNB 미적용(모바일)건 수정
    &.receipt {
        .page-header {
            @include mobile {
                .title {
                    display: block;
                    margin-top: 10px;
                }
            }
        }
    }

    .page-header {
        @include mobile {
            padding: 0 16px;
        }

        .progress-wrap {
            margin-top:20px;
            margin-bottom:32px;
            &.type2 {
                margin-top:8px;
                margin-bottom:24px;
                .progress-count {
                    padding-bottom:8px;
                    font-size:12px;
                    line-height:1.42;
                    text-align:left;
                    strong {
                        color:#ea1917;
                    }
                    span {
                        color:#8F8F8F;
                    }    
                }
                ol {
                    overflow:hidden;
                    &:before {
                        top:50%;
                        left:0;
                        right:0;
                        transform:translateY(-50%);
                        background-color: #8f8f8f;
                    }
                    li {
                        position:relative;
                        width:16px !important;
                        height:12px;
                        padding:0 2px;
                        background:#FFF !important;
                        z-index:2;
                        &:first-of-type {
                            z-index:7;
                        }
                        &:nth-child(2) {
                            z-index:6;
                        } 
                        &:nth-child(3) {
                            z-index:5;
                        } 
                        &:nth-child(4) {
                            z-index:4;
                        } 
                        &:nth-child(5) {
                            z-index:3;
                        } 
                        &:nth-child(6) {
                            z-index:2;
                        } 
                        &:nth-child(7) {
                            z-index:1;
                        }                          
                        &:before {
                            width:12px;
                            height:12px;
                            margin:0;
                            border:3px solid #8f8f8f;
                            border-radius:6px; 
                            box-sizing:border-box;
                            content:'';
                        }
                        &.on {
                            &:before {
                                background-color:#ea1917;                                  
                                border-color:#ea1917;                                     
                            } 
                            &:after {
                                position:absolute;
                                right:10px;
                                width:100vw;
                                height:2px;
                                background-color:#ea1917; 
                                content:'' !important;
                                @include pc {
                                    right:16px;
                                }
                            }    
                        }
                        &.ing {
                            &:before {
                                background-color:#FFF;                                  
                                border-color:#ea1917;                                     
                            } 
                            &:after {
                                display:block;
                                position:absolute;
                                right:10px;
                                width:100vw;
                                height:2px;
                                background-color:#ea1917; 
                                content:'' !important;
                                @include pc {
                                    right:16px;
                                    width:100vw;
                                }
                            }    
                        }
                        
                        &:first-of-type.on,
                        &:first-of-type.ing {
                            &:after {
                                display:none;
                            } 
                        }
                        &:last-of-type.on,
                        &:last-of-type.ing {
                            &:after {
                                right:16px;
                            } 
                        }
                    }
                }
            }
        }

        .btn-desc {
            @include mobile {
                .btn + .btn {
                    margin-top:8px;
                }
            }
        }
        //상담예약, 출장, 센터방문
        & + .service-engineer{
            .step-box:first-of-type {
                margin-top: -16px;
            }
        }
        & + .visit-center{
            .step-box:first-of-type {
                margin-top: -16px;
            }
        }
    }    

    // 예약 조회/변경
    .service-status-detail {
        .page-header {
            //BTOCSITE-79119 ERMS -> Smile+ 전환 개발
            .progress-wrap.type2 {
                overflow:hidden;
                padding-bottom:24px;
                margin-bottom:0;
                ol {
                    overflow: initial;
                    li {
                        &.on {
                            &.curr {
                                .txt {
                                    display:block;
                                }
                            }
                        }
                        .txt {
                            display:none;
                            position: absolute;
                            left: 50%;
                            bottom: -23px;
                            transform: translateX(-50%);
                            color: #000;
                            @include font-small;
                            text-align: center;
                            white-space: nowrap;
                        }
                        &:first-of-type {
                            .txt {
                                left: 0;
                                transform: translateX(0);
                            }
                        }
                        &:last-of-type {
                            .txt {
                                left: 100%;
                                transform: translateX(-100%);
                            }
                        }
                    }
                    
                }
            }
        }
    }

    /* 2023.04.18 Add */
    .step-box {
        .form-infos {
            margin-top:16px;
            margin-bottom:16px;
        }
    }

    /* 2023.04.17 Add */
    .cont-wrap {
        .section {
            &.top-border {
                margin-top:36px;              
                &:before {
                    display:block;
                    height:1px;
                    margin:0 0 24px;
                    background:#666;
                    content:'';
                }
            }
            &-only {
                padding:0 16px;
            }
            .tit-wrap {
                margin-bottom:16px;
                .tit { 
                    &.small {
                        padding-bottom:8px;               
                        border-bottom:2px solid #000;
                        font-weight:bold;
                        font-size:14px;
                        line-height:20px;
                    }
                    
                    &.medium {
                        font-size:20px;
                        line-height: 1.2;
                    } 
                    .tooltip-wrap {
                        padding-top:0;
                    }
                }
                &.no-border {
                    margin-bottom:12px;
                    .tit {
                        border-bottom:none;
                        padding-bottom:0;
                    }
                }
                &.mt {
                    margin-top:40px;
                }
            } 
            ~ .section {
                margin-top:32px;
                padding-top:24px;
                border-top-width:10px;
            }
            .complete-summary {
                padding:0;
            }
            // 스스로해결 > 다운로드 센터
            &.manual-section {
                .banner-wrap {
                    margin:24px 0 32px;
                }
                .section-box {
                    margin-top:24px; 
                }
            }
        } 
        .complete-summary + .section {
            &.top-border {
                margin-top:0;
            }    
        }
        .section-box {
            .prod-search-wrap {
                padding:0;                
                + .download-list-wrap {
                    margin-top:12px !important;
                }
            }
            + .directCall-wrap {
                margin:32px 0;
                padding:0;
            }
            + .section-box {
                margin-top:24px; /* 재정의:2023.05.24 */
            }
        }
    }
}/* .container */

.sorting-wrap .total {
    font-weight:400;
}

.sorting-wrap .total {
    font-weight:400;
}

.support {
    // BTOCSITE-62364 모델번호, 제조번호 입력    
    .prod-model-box {
        border-radius: 8px;
        padding: 18px 18px 24px 18px;
        box-shadow:0 4px 16px 0 rgba(0, 0, 0, 0.14);
        
        &.open {
            border: 1px solid #666;
            box-shadow:0 4px 16px 0 rgba(0, 0, 0, 0.14);
            .form-wrap {
                display: block;
            }
            .search-more {
                display: block;
            }
            .tit-wrap {
                .btn-prod-open {
                    &:after {
                        display: none;
                        transform: rotate(-180deg);
                    }
                    
                }
            }
            .noti-msg {
                display: none;
            }
        }

        &.red {
            .noti-msg {
                color: #FF3723;
                &:before {
                    background: url(/lg5-common/images/icons/icon-error.svg) no-repeat 0 0;
                    background-size: 100% auto;
                }
            }
        } 
        &:first-of-type {
            margin-top: 16px;
        }
        & ~ .prod-model-box {
            margin-top: 10px;
        }
        .tit-wrap {
            margin-bottom: 0 !important;
            .btn-prod-open {
                position: relative;
                width: 100%;
                margin: 0;
                
                .tit {
                    text-align: left;
                    font-size: 14px;
                    line-height: 20px;
                    border: none;
                    padding: 0;
                    margin: 0 !important;
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 16px;
                    height: 16px;
                    background: url(/lg5-common/images/icons/btn-down-16-black.svg) no-repeat 0 0;
                }
                
            }
        }
        .form-wrap {
            display: none;
            margin-top: 16px;
        }
        
        // BTOCSITE-64881 고객지원 > 서비스예약 > 모델명, 제조번호 입력 > UI일부 수정
        .txt-wrap {
            .noti-txt {
                margin-top: 8px;
                text-align: left;
                font-size: 13px;
                line-height: 20px;
                color: $color-negative;
            }
        }
        .noti-msg {
            position:relative;
            display: block;
            font-size: 13px;
            line-height: 20px;
            color: #ff3723;
            padding-left: 16px;
            margin-top: 10px;
            text-align: left;
            &:before {
                position: absolute;
                left: 0;
                top: 3px;
                content: '';
                display: inline-block;
                width: 12px;
                height: 12px;
                background: url(/lg5-common/images/icons/icon-error.svg) no-repeat 0 0;
                background-size: 100% auto;
                
            }
            &.red {
                color: #FF3723;
                &:before {
                    background: url(/lg5-common/images/icons/icon-error.svg) no-repeat 0 0;
                    background-size: 100% auto;
                }
            }
        }

        .success-msg {
            color: #76892d;
            font-size: 14px;
            line-height: 24px;
            white-space: nowrap;
            margin-top: 8px;
            // position: absolute;
            // left: 0;
            border: 0;
            &::before {
                content: ' ';
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-top: 2px;
                margin-right: 2px;
                background: url(/lg5-common/images/icons/icon-comp.svg) center no-repeat;
                vertical-align: top;
            }
        }
        .keyword-search {
            &.on {
                .search-input {

                    input[type=text] {
                        border: 0;
                    }
                }
            }
            .search-input {
                display: flex;
                input[type=text] {
                    height: 46px;
                    border: 1px solid #8F8F8F;
                    border-radius: 8px;
                    padding: 11px 16px 11px 16px;
                    box-shadow: none;
                    font-size: 14px;
                    line-height: 20px;
                    &:focus {
                        outline:none;
                        border-color:#000;
                    }
                }

                input[type=text] ~ .btn-search {
                    width: 86px;
                    margin-left: 8px;
                }
            }
            .search-layer {
                top: -1px;
                left: -1px;
                right: 78px;
                width: auto;
                min-width: auto;
                max-height: 200px;
                border-radius: 6px;
                border: 1px solid #0F0F0F;
                padding: 47px 16px 16px;
                .keyword-box {
                    position: absolute;
                    top: 50%;
                    left:16px; 
                    right:16px;
                    .keyword-list {
                        margin: 10px 0 0 0;
                    }
                }
                .autocomplete-box {
                    .keyword-list {
                        margin:0;
                        
                        & > ul {
                            margin:0;
                            
                            & > li {
                                padding: 0;
                                a {
                                    font-size: 14px;
                                    line-height: 20px;
                                    color: #AAAAAA;
                                }
                            }
                        }
                    }
                }
            }
            .btn-search {
                position: relative;
                color: #FFF;
                font-size: 13px;
                line-height: 20px;
                font-weight: bold;
                height: 46px;
                padding: 10px 24px 10px 24px;
                background-image: none;
                background-color: #0F0F0F;
                border-radius: 6px;
                span {
                    color: #FFF;
                }
            }

            .btn-delete {
                right: 94px;
            }
        }

        .search-more {
            display: none;
            text-align: center;
            margin-top: 48px;
            margin-bottom: 16px;
            
        }
        input[type=text] {
            color: #0F0F0F;
            border-color: #8F8F8F;
            padding: 11px 16px;
            &::placeholder {
                color: #8F8F8F;
            }
            &:focus {
                border-color: #0F0F0F;
                outline: none;
            }
        }
        .select-wrap .ui-selectbox-wrap .ui-select-button {
            border-color: #8F8F8F;
            color: #0F0F0F;
        }
        .btn.border.arrow {
            padding: 7px 32px 7px 12px;
            &::after {
                right: 8px;
                margin-top: -9px;
            }

        }
        .btn.border.arrow:active, 
        .btn.border.arrow:focus, 
        .btn.border.arrow:hover {
            border-color: #0F0F0F !important;
            background-color: #FFF;
            color: #0F0F0F !important;
        }

        //BTOCSITE-64551 고객지원 > 출장접수 > 시스템에어컨 안내 추가
        .noti-txt {
            color: #666;
            font-size: 14px;
            line-height: 20px;
            margin-top: 20px;
            em {
                color: #ff3723;
            }
        }
    }

    // BTOCSITE-3200 출장서비스 예약 > 추가정보_건물유형 수정 등 
    .cause-desc {
        margin-top:16px;

        p {
            position: relative;
            padding-left:16px;
            color:#f00;
            font-size:14px;
            line-height:1.4;
            font-weight:bold;
            &:before {
                position: absolute;
                top:0;
                left:0;
                content:"※";
                display: inline-block;
            }

            @include mobile {
                font-size:13px;
            }
        }
    }
    .tooltip-wrap {
        &.share {
            .tooltip-box {
                padding-right:32px;
                min-width:320px;
            }
            .btn-close {
                top:32px;
                right:32px;
            }
        }
        .btn-close {
            top:10px !important;
        }

        .tooltip-box {
            padding-right:40px;
            border-radius:6px;
            min-width:250px;
            .bullet-list {
                margin-top:12px;
            }
        }
        .title {
            font-weight:bold;
            font-size:16px;
            line-height:22px;
        }

        @include mobile {
            &.share {
                .tooltip-box {
                    padding-right:24px;
                }

                .btn-close {
                    top:24px;
                    right:24px;
                }
            }
            .btn-close {
                top:8px;
                right:10px;
            }
        }
    }

    .login-guide {
        display:flex;
        align-items:center;
        justify-content:space-between;
        margin:0 16px 10px;
        padding:8px 12px 8px 16px;
        background:#f8f8f8;
        border-radius:8px;
        text-align:center;
        .txt {
            margin:0;
            font-weight:normal;
            font-size:12px;
            line-height:1.42;
            text-align:left;            
            word-break:keep-all;
        }
    }
    .service-menu-wrap {
        .inner {
            max-width:1380px + 40px;
            margin:0 auto;
            padding:120px 40px;
        }
        .title {
            margin-bottom:48px;
            font-size:32px;
            font-weight:700;
            line-height:48px;
            text-align:center;
            word-break:keep-all;
        }
        .service-list {
            margin:0 -12px;
            font-size:0;
            > li {
                display:inline-block;
                width:calc(100% / 6);
                padding:0 12px;
                vertical-align:top;
                a {
                    display:block;
                }
                .icon {
                    width:80px;
                    height:80px;
                    margin:0 auto;
                    img {
                        width:100%;
                    }
                }
                .tit {
                    display:block;
                    margin-top:8px;
                    @include font-large-1;
                    font-weight:700;
                    text-align:center;
                }
            }
        }
        @include tablet {
            .service-list {
                margin:-24px -12px 0;
                > li {
                    padding:24px 12px 0;
                    width:calc(100% / 3);
                }
            }
        }
        @include mobile {
            border-top:12px solid #f4f4f4;
            .inner {
                padding:32px 16px 60px;
            }
            .title {
                margin-bottom:24px;
                font-size:24px;
                line-height:33px;
            }
            .service-list {
                margin:-40px -4px 0;
                > li {
                    display:inline-block;
                    width:50%;
                    padding:40px 4px 0;
                }
            }
        }
    }
    .held-product {
        padding-left: 22px;
        font-size: 14px;
        font-weight: 500;
        color: #767676;
        background: url(/lg5-common/images/icons/icon-more-pk-20.svg) no-repeat left center;
    }


    .search-info {
        background-color: #f4f4f4;
        text-align: center;
        padding: 32px 0;
        border-radius: 8px;
        margin-bottom: 80px;
        .tit {
            margin-bottom:16px;
            font-size:20px;
            font-weight:700;
            line-height:24px;
        }
        .desc {
            font-size:16px;
            line-height:24px;
            .point {
                color:#ea1917;
                font-weight:normal;
            }
        }

        &.type-A {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 24px 48px;
            margin-top:10px;
            margin-bottom: 60px;
            text-align: left;
            .text {
                font-size: 16px;
                line-height: 1.5;
            }
        }
        &.type-B {
            font-size: 24px;
            line-height: 1.42;
            padding: 24px;
            margin-bottom: 12px;
        }
        @include tablet {
            &.type-A {
                display: block;

                .btn-link {
                    margin-top: 12px;
                }
            }
        }
        @include mobile {
            padding: 20px;
            margin-bottom: 40px;
            .tit {
                margin-bottom:12px;
                font-size:16px;
                line-height:24px;
            }
            .desc {
                font-size:14px;
                line-height:22px;
            }
            &.type-A {
                display: block;
                padding: 20px 24px;
                margin:0;
                .text {
                    font-size: 14px;
                }
            }
            &.type-B {
                font-size: 18px;
                padding: 20px 16px;
            }
        }
    }
    .scroll-x {
        overflow-x: auto;
        table {
            min-width: 720px;
            width: calc(100% - 1px);
        }
    }

    .accordion-wrap {
        &.border-type {
            > ul {
                > .lists {
                    border-radius: 8px;
                    border: solid 1px #dddddd;
                    margin-top: 12px;
                    box-shadow: none;
                    .head {
                        .accord-btn {
                            padding: 24px 32px;
                            &::after {
                                content: '보기';
                                border: none;
                                width: auto;
                                height: auto;
                                right:28px;
                                font-size: 16px;
                                color: #000;
                                line-height: 1.63;
                                transform: translateY(-50%);
                                margin-top: 0;
                                padding-left: 20px;
                                background: url(/lg5-common/images/icons/icon-more-plus_16_w.svg) no-repeat left center;
                                background-size: 16px;
                                text-decoration: underline;
                            }

                            .subtitle {
                                &.gray {
                                    color:#666;
                                }
                                strong {
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                    &.on {
                        .head {
                            .accord-btn {
                                &::after {
                                    content: '닫기';
                                    background-image: url(/lg5-common/images/icons/btn-close-24.svg);
                                }
                            }
                        }
                    }
                    .accord-cont {
                        margin: 0 24px 24px 24px;
                        padding: 24px 32px;
                        border-radius: 8px;
                        background-color: #f4f4f4;
                        display: none;
                        &.no-bg-type {
                            padding: 24px 32px 32px;
                            background-color: transparent;
                        }
                    }
                }
            }
        }
        @include mobile {
            &.border-type {
                > ul {
                    > .lists {
                        .head {
                            .accord-btn {
                                padding: 20px;

                                .subtitle {
                                    font-size:14px;
                                }

                                &::after {
                                    font-size:14px;
                                }
                            }
                        }
                        
                        .accord-cont {
                            margin: 0 20px 20px 20px;
                            padding: 24px 16px 27px;
                            &.no-bg-type {
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    

    .search-wrap {
        &.narrow {
            max-width:912px;
            margin:0 auto;
        }
        &.board-top-search {
            margin-bottom:45px;
        }

        .sort-search {
            display:flex;
            border-radius:8px;
            border:1px solid #ddd;
            
            .select-wrap {
                width:152px;
                // BTOCSITE-28887 [요금/보증안내] 모니터 보증기간 제품 추가 : 로딩시 깜박이는 현상 수정
                select {
                    color: #767676;
                    font-size: 14px;
                    line-height: 22px;
                    margin: 11px;
                    // BTOCSITE-30896 [모니터링]재생부품 가격 공지 페이지 최초 진입시 일시적으로 제품선택 문구가 비정상 노출 후 정상적으로 노출됨
                    @include pc {
                        font-size: 16px;
                        line-height: 26px;
                        margin: 10px 15px;
                    }
                }
                .ui-selectbox-wrap {
                    .ui-select-button {
                        border:0;
                    }

                    &.on {
                        .ui-select-button {
                            border:1px solid #000;
                            border-bottom:0;
                        }
                    }
                }

            }
            .input-wrap {
                position:relative;
                flex:1;

                input[type="text"] {
                    width:100%;
                    border:0;
                    text-transform:uppercase;
                }
            }
        }
        @include pc {
            .search-box {
                .forms {
                    display: flex;
                    border: 1px solid #ddd;
                    border-radius: 8px;
                    .box {
                        margin-top: 0;
                    }
                    .select-wrap {
                        width: 152px;
                        .ui-selectbox-wrap {
                            margin: -1px 0 -1px -1px;
                            .ui-select-button {
                                border-right-color: transparent;
                                border-bottom: none;
                                border-bottom-right-radius: 0;
                                border-top-right-radius: 0;
                            }
                            &.on {
                                .ui-select-button {
                                    border-top-right-radius: 8px;
                                    border-right-color: #222;
                                }
                            }
                        }
                    }
                }
            }
            &.w-block {
                max-width: 100%;
            }
        }
        @include mobile {
            &.w-block {
                padding: 0;
            }
        }
    }

    .keyword-search .search-input input[type=text]

    .select-wrap {
        .ui-selectbox-wrap {
            .ui-selectbox-list {
                z-index: 12;
            }
        }
    }

    

    

    .directCall-wrap {
        max-width: 1380px + 80px;
        margin:0;
        padding:0;
        >ul {
            >.lists {
                margin-top: 0;
                /* S: 2023.05.08 */
                border-color:#222 !important;
                .head {
                    .accord-btn {
                        padding:15px 16px 15px 24px !important;
                        .subtitle {
                            line-height:20px;
                        }
                        &:after {
                            right:16px !important;
                            background-image:url(/lg5-common/images/CS/icon/icon_more_32.svg) !important;
                        }
                    }
                }
                &.on {
                    .head {
                        .accord-btn {
                            &:after {
                                background-image:url(/lg5-common/images/CS/icon/icon_minus_32.svg) !important;
                                content:"접기" !important;    
                            }
                        }
                    }
                }
                .accord-cont {
                    margin:0 !important;
                    padding:9px 24px 24px !important;
                    background-color:#FFF !important;
                }
                .inquiry-list {
                    > li {
                        flex-direction:column;
                        .title {
                            width:100%;
                        }
                        .description {
                            width:100%;
                            margin-top:8px;
                        }
                    }
                }
                /* E: 2023.05.08 */
            }
        }
    }

    

    .box-info {
        margin-top: 16px;
        @include pc {
            .status-icon {
                margin-left: 6px;
                &:first-of-type {
                    margin-left: 0;
                }
            }
        }
    }
    .status-icon {
        display:inline-flex;
        align-items:center;
        .status {
            @include font-small;
            font-weight:$font-weight-medium;
        }
        .text  {
            @include font-small;
            color: #666;
            margin-left:9px;
        }
        &:before {
            content:'';
            display:inline-block;
            width:12px;
            height:12px;
            border-radius:50%;
            vertical-align:middle;
            margin-right:5px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center center;
        }
        &.gray:before {
            background-color: #ccc;
        }
        &.gray2:before {
            background-color: #e4e4e4;
        }
        &.green:before {
            background-color: #51b1ac;
        }
        &.red:before {
            // background-color: #e51346;
            background-image: url("/lg5-common/images/CS/icon-state-no.svg");
        }
        &.black:before {
            background-color: #222;
        }
        &.skyblue:before {
            // background:#21a9aa;
            background-image: url("/lg5-common/images/CS/icon-state-ok.svg");
        }
        &.olive:before {
            // background:#76892d;
            background-image: url("/lg5-common/images/CS/icon-state-mid.svg");
        }
        &.gradient1::before {
            background-image: linear-gradient(135deg, #8d71ec, #ff537c);
        }
    }

    .optionIcon {
        display: flex;
        margin-top: 16px;
        li {
            &:nth-child(n+2) {
                margin-left: 32px;
            }
        }
    }

    .consulting-info-tit,
    .consulting-info-desc {
        text-align: center;
        color: #000;
    }
    .consulting-info-tit {
        font-size: 28px;
        line-height:1.63;
        font-weight: bold;
        word-break: keep-all;
        margin-bottom: 8px;
        @include mobile {
            font-size: 18px;
            line-height: 1.44;
        }
    }
    .consulting-info-desc {
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 32px;
        @include mobile {
            font-size: 13px;
            line-height: 1.62;
        }
    }
    .consulting-info {
        color: #000;
        max-width: 912px;
        margin: 0 auto;
        .info-tit,
        .info-desc {
            text-align: center;
        }

        @include mobile {
            .bullet-list {
                margin-top:32px;
            }
        }
    }

    .consulting-keyword {
        margin-bottom: 32px;
        .item {
            border-radius: 8px;
            padding:16px 0;
            color:#fff;
            text-align:center;
            flex:1;
            &:nth-child(1) {
                background-image:linear-gradient(to right, #a58bee, #f86791);
            }
            &:nth-child(2) {
                background-image:linear-gradient(to right, #a58bee, #f86791);
            }
            &:nth-child(3) {
                background-image:linear-gradient(to right, #a58bee, #f86791);
            }
            .title {
                line-height:30px;
                font-size:20px;
                font-weight:$font-weight-bold;
            }
            .text {
                line-height:24px;
                font-size:14px;
                font-weight:$font-weight-medium;
            }
        }
        @include pc {
            display:flex;
            justify-content:center;
            .item {
                margin-left:16px;
                &:first-child {
                    margin-left:0;
                }
            }
        }
        @include mobile {
            .item {
                margin-top:12px;
                &:first-child {
                    margin-top:0;
                }
                .title {
                    line-height:23px;
                    font-size:15px;
                }
                .text {
                    line-height:21px;
                    font-size:13px;
                }
            }
        }
    }

    .step-list {
        display: flex;
        justify-content: center;
        text-align: center;
        li {
            width: 104px;
            height: 104px;
            background-color: #f4f4f4;
            border-radius: 50%;
            padding: 0 20px;
            margin-left: 40px;
            font-size: 16px;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            &:first-child {
                margin-left: 0;
                &::before {
                    display: none;
                }
            }
            &::before {
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                background:url(/lg5-common/images/icons/btn-arr-16-black.svg) no-repeat;
                position: absolute;
                left: -25px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        @include mobile {
            flex-wrap: wrap;
            li {
                font-size: 14px;
                width: 80px;
                height: 80px;
                margin-left: 23px;
                margin-bottom: 12px;
                &::before {
                    left: -19px;
                }
            }
        }
    }

    .sw-notice {
        &-title {
            font-size: 24px;
            font-weight: bold;
            line-height: 1.42;
            color: #222222;
            margin-bottom: 16px;
            text-align: center;
        }
        &-desc {
            font-size: 16px;
            line-height: 1.63;
            color: #222222;
            text-align: center;
            margin-bottom: 32px;
        }
        &-ul {
            font-size: 16px;
            line-height: 1.63;
            color: #222222;
        }
    }
    .patch-note {
        padding: 24px 40px;
        border-radius: 8px;
        background-color: #f4f4f4;
        &-title {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.63;
            color: #222222;
            padding-left: 23px;
        }
        .bullet-list {
            font-size: 16px;
        }
        @include mobile {
            padding: 20px;
        }
    }

    .mobile-notice{
        margin:0 auto;font-size:16px;
        .notice_title{
            font-size: 24px;
            color: #222222;
            margin-bottom: 16px;
            text-align: center;
        }
        .patch-note{
            margin-top: 38px;
            padding: 24px 40px;
            border-radius: 8px;
            background-color: #f4f4f4;
            em{
                display: block;
                font-size: 16px;
                font-weight: bold;
                line-height: 1.63;
                color: #222222;
                padding-left: 23px;
                margin-bottom: 12px;
                background: url(/lg5-common/images/icons/icon-error.svg) no-repeat left center;
            }
            ul { 
                margin-top:0;
                li{
                    font-size: 14px;
                    line-height: 1.63;
                    color: #767676;
                    text-indent: -0.5em;
                    padding-left: 0.5em;
                }
            }
        }
        .txt_thx {
            margin-top: 12px;
        }
        @include mobile {
            .patch-note {
                padding: 16px;
                em {
                    font-size: 14px;
                }
            }
        }
    }
    
    .mobile-contents{
        margin:30px 0 0;
        p {
            font-size: 20px;
            font-weight: bold;
            color: #222222;
            margin-bottom: 16px;
        }
        ul { 
            > li {
                font-size: 14px;
                line-height: 1.63;
                text-indent: -0.5em;
                padding-left: 0.5em;
            }
            strong{
                font-size: 16px;
                line-height: 1.63;
                font-weight: normal;
                color: #222222;
            }
        }
        .detail{
            margin:10px 0 20px;
        }
        .title{
            display:block;position:relative;margin:30px 0;padding-left:20px;font-size:18px;font-weight:600;
        }
        .title:before{
            content:'';display:inline-block;position:absolute;left:0;top:5px;border-radius:10px;width:10px;height:10px;background:#ce1848;
        }
        @include mobile {
            p {
                font-size: 16px;
                margin-bottom: 12px;
            }
            ul {
                strong {
                    font-size: 14px;
                }
            }
        }
    }

    .btn-group {
        @include pc {
            .block-box {
                display: inline-block;
                margin-left: 6px;
            }
        }
        @include mobile {
            .btn {
                &.wide-type {
                    width: 100%;
                    margin-bottom: 12px;
                }
            }
        }
    }

    .bullet-list {
        // BTOCSITE-21883 고객지원: 베스트케어 지원
        .a-link {
            text-decoration: underline;
        }
        .btn-link {
            margin-left: 13px;
            font-size: inherit;
            line-height: inherit;
            
            // BTOCSITE-27102 [원격상담] 안내 문구 영역 추가 및 PC/노트북 원격지원 프로그램 다운로드 버튼 제공
            &.point {
                &::after {
                    background: url(/lg5-common/images/icons/btn-arr-16-red.svg) center no-repeat;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        // BTOCSITE-21062 출장서비스 신청 약관 하단에 문구 추가 요청 건
        .b-dangu {
            padding-left: 17px;
            text-indent: -17px;
            &::before {
                content: '※ ';
                display: inline;
            }
        }
    }

    .video-box {
        height:360px;
        margin:0 auto;
        display: flex;
        .column {
            width:56%;
            height:100%;
            & + .column {
                width:calc(44% - 24px);
                margin-left:24px;
            }
            iframe {
                width:100%;
                height:100%;
            }
            .subtitles-tit {
                margin-bottom: 24px;
                color: #000;
                font-size:24px;
                font-weight:$font-weight-bold;
                line-height:34px;
                @include mobile {
                    margin-bottom: 12px;
                    font-size: 20px;
                    line-height:1.4;
                }
            }
            .subtitles-cont {
                overflow-y:auto;
                height: calc(100% - 58px);
                padding-right: 36px;
                color:#000;
                font-size:16px;
                line-height:26px;
                white-space: pre-line;
            }
        }
        @include mobile {
            height:auto;
            flex-direction: column;
            .column {
                float:none;
                width:100%;
                height:auto;
                & + .column {
                    width:100%;
                    margin:8px 0 0;
                }
                iframe {
                    height: 220px;
                }
                .subtitles-wrap {
                    height: 165px;
                    font-size:13px;
                    line-height:21px;
                }
                .subtitles-cont {
                    height: 58.3336vw;
                }
            }
        }
    }
    .request-link-box {
        max-width: 1380px;
        margin: 0 auto 24px;
        @include mobile {
            padding: 0 16px;
        }
    }
    .result-progress {
        max-width:1380px + 80px;
        margin:0 auto;
        padding:80px 40px 0;
        &:first-child {
            padding-top:0;
        }
        .desc{
            margin-bottom:24px;
            @include font-medium;
            font-weight:700;
        }
        .progress-box {
            position:relative;
            &:after {
                content:'';
                position:absolute;
                left:0%;
                top:6px;
                width:100%;
                height:12px;
                background:#f4f4f4;
            }
            .progressing {
                position:absolute;
                z-index:1;
                left:0%;
                top:6px;
                width:0;
                height:12px;
                border-radius:16px;
                background:#ea1917;
            }
            .progressbar {
                display:flex;
                justify-content:space-between;
                padding-bottom:30px;
                li {
                    position:relative;
                    text-align:center;
                    &:before {
                        content: '';
                        display:inline-block;
                        width:24px;
                        height:24px;
                        background-color:#aaa;
                        border-radius: 50%;
                        position: relative;
                        z-index:2;
                    }
                    .text {
                        display:none;
                        position:absolute;
                        left:50%;
                        bottom:-30px;
                        white-space:nowrap;
                        transform:translateX(-50%);
                        font-weight:500;
                        @include font-medium;
                    }
                    &:first-child .text {
                        left:0;
                        transform:translateX(0);
                    }
                    &:last-child .text {
                        left:auto;
                        right:0;
                        transform:translateX(0);
                    }
                    &.on {
                        &:before{
                            border:1px solid #ddd;
                            box-shadow:2px 4px 8px 0 rgba(0, 0, 0, 0.14);
                            background: url(/lg5-common/images/CS/icon-process-complete-24.svg) no-repeat center #fff;
                        }
                    }
                    &.current {
                        .text {
                            display:block;
                        }
                    }
                }
            }
        }
        @include mobile {
            padding:60px 16px 0;
            .progress-box {
                &:after {
                    height:8px;
                }
                .progressing {
                    height:8px;
                }
                .progressbar {
                    padding-bottom:28px;
                    li {
                        &:before {
                            width:20px;
                            height:20px;
                        }
                        .text {
                            bottom:-28px;
                        }
                    }
                }
            }
        }
    }

    &.error-page-cs {
        padding:0 40px;
        padding-top:((280px * 100%) / 1920px);
        padding-bottom:((280px * 100%) / 1920px);
        text-align:center;
        br.m-br {
            display:none;
        }
        .error-inner {
            display:inline-block;
            vertical-align:top;
            .tit {
                font-size:40px;
                line-height:56px;
                font-weight:normal;
                small {
                    display:block;
                    margin-top:24px;
                    margin-bottom:40px;
                    @include font-large;
                    font-weight:700;
                }
                @include mobile {
                    font-size:27px;
                    line-height:38px;
                }
                &.icons {
                    &::before {
                        content:' ';
                        display:block;
                        width:80px;
                        height:80px;
                        margin:0 auto 24px;
                        background:url('/lg5-common/images/icons/icon-fixed-80.svg') no-repeat;
                        @include mobile {
                            margin-bottom:20px;
                        }
                    }
                }
            }
            .desc {
                margin-top:24px;
                @include font-large;
                span {
                    display:inline-block;
                    vertical-align:top;
                }
                @include mobile {
                    margin-top:16px;
                }
            }
            dl {
                display:block;
                margin-top:24px;
                margin-bottom:120px;
                font-size:0;
                @include mobile {
                    margin-top:18px;
                }
                dt {
                    display:inline-block;
                    @include font-large;
                    font-weight:700;
                    vertical-align:top;
                }
                dd {
                    display:inline-block;
                    margin-left:12px;
                    color:#ea1917;
                    @include font-large;
                    font-weight:700;
                    @include mobile {
                        margin-left:8px;
                    }
                }
            }
            .ci {
                margin-top:32px;
                text-align:center;
                @include mobile {
                    margin-top:60px;
                }
                img {
                    max-width:82px;
                    height:auto;
                    vertical-align:top;
                }
            }
            .btns {
                margin-top:120px;
                @include mobile {
                    padding:0 16px 40px;
                    .btn {
                        display:block;
                        margin-top: 12px;
                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        @include mobile {
            padding:0 16px;
            padding-top:((260px * 100%) / 720px);
            br.m-br {
                display:block;
            }
        }
    }

    .card-news-tit {
        padding:24px 40px;
        @include font-medium;
        border-radius:8px;
        background:#f4f4f4;
        strong {
            font-weight:$font-weight-bold;
        }
    }

    &.popup-wrap {
        // 레이어팝업 체크박스

        &.row-footer {

            .pop-conts {
                @include mobile {
                    height:calc(100% - 122px - 36px);
                    max-height:calc(100% - 122px - 36px);
                }
            }
        }
        .pop-footer {
            &.check-type {
                input {
                    &+label {
                        padding-left: 30px;
                        font-size: 16px;
                        line-height: 26px;

                        @include mobile {
                            font-size:13px;
                        }
                        &::before {
                            width: 20px;
                            height: 20px;
                        }
                        &::after {
                            top: 2px;
                            left: 0px;
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
            &.align-between {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
    // 2023.04.17 제품선택리스트 Add
    .productList-title {
        margin:16px 0 12px;
        .tit {
            font-size:14px;
            line-height:20px;
            font-weight:700;
        }
    }
    .ownProductList {
        ul {
            li {
                padding:20px;
                margin-top:12px;
                border:1px solid #ddd;
                border-radius:8px;
                &:first-child {
                    margin-top:0;
                }
                .rdo-wrap, .ownProductList ul li label {
                    width:100%;
                }
                label{
                    span {
                        display:block;                        
                    }
                    .group {
                        font-size:10px;
                        line-height:15px;
                        color:#ea1917;
                    }
                    .name {
                        font-weight:bold;
                        font-size:13px;
                        line-height:19px;
                        @include textEllipsisClamp(1);
                    }
                    .serial {
                        margin-top:2px;
                        font-size:10px;
                        line-height:15px;
                        color:#666;
                    }
                    &:after {top:50%; margin-top:-12px}
                }                
                .status {
                    position: absolute;
                    top:-6px;
                    right:-6px;
                    padding:3px 6px 4px;  
                    border:1px solid #DDD;
                    border-radius:4px;                  
                    font-weight:bold;
                    font-size:11px;
                    line-height:1;  
                    color:#666;                  
                }
            }
        }
    }
    .step-box .ownProductList { margin-top:12px; padding-bottom:40px; }

    /* 스스로 해결 */
    &.solutions {
        .login-guide {
            margin-top:16px;
            + .cont-wrap {
                padding-top:0;
            }
        }
        // .prod-selected-wrap {
        //     margin-top:8px;
        // }
        .banner-wrap.toggle-banner {
            .cont {
                p {
                    font-size: 13px;
                    line-height: 20px;
                }
                .btn-link {
                    font-size: 13px;
                    margin-top: 10px;
                }
            }    
            .banner {
                padding: 20px;
                background:url('/lg5-common/images/CS/bg_downloadcenter_banner_mo.jpg') #D3E5FD no-repeat right 50% / auto 100%;
            }
        }

        .list-wrap {
            .list>li {
                .item {
                    .tit {
                        @include textClipClamp(2);
                        white-space: inherit;
                    }
                }
            }
        }
    }
    .solutions-wrap {
        margin-top:24px;
        .title {
            .tit {
                .point {
                    color:#ea1917 !important;
                }
            }
        }
        .section {
            .search-wrap {
                margin-bottom:0;
                + .mobile-filter-box {
                    .form-wrap {
                        .forms {
                            .conts {
                                margin-top:20px;
                            }
                        }
                    }
                }
            }
            .result-box {
                .title {
                    margin-bottom:0;
                    padding-bottom:28px;
                    border-bottom:0;
                }
            }
        }
        .btn-select-link {
            margin-top:24px;
            text-align:center;
        }
        .result-box {
            float:none !important;
            width:100% !important;
        }
    }

    .mobile-filter-box {
        margin-bottom:32px;
        .title {
            padding-bottom:0 !important;
            margin-bottom:0 !important;
            margin-top:24px;
            border-bottom:none !important;
            .tit {
                font-size:14px !important;
            }
        }
    }

    /* .common-banner */    
    .common-banner {
        border-top-width:10px;
        .banner {
            .banner-tit {
                margin-bottom:16px;
                font-size:16px;
                line-height:22px;
                text-align:left;
            }
            .service-list-wrap {
                .service-list {
                    margin:0;
                    > li {
                        width:100%;
                        margin-top:12px;
                        padding:16px 24px;
                        border:1px solid #ddd; 
                        border-radius:8px; 
                        &:first-of-type {
                            margin-top:0;    
                        } 
                        a {
                            display:flex;
                            align-items:center;
                        }
                        .icon {
                            width:40px;
                            height:40px;
                            margin:0;
                            img {
                                width:auto;
                                height:100%;
                            }
                        }
                        .cont {
                            margin-left:19px;
                            text-align:left;
                            .btn-txt {
                                display:block;
                                font-weight:bold;
                                font-size:16px;
                                line-height:22px;
                                color:#000;
                            }
                            .icon-arrow {
                                display:inline-block;
                                width:16px;
                                height:16px;
                                margin-left:4px;
                                background:url(/lg5-common/images/icons/btn-arr-24x24-black.svg) no-repeat;
                                background-size:100% 100%;
                                vertical-align:-3px;
                            }
                            .desc {
                                display:block;
                                margin-top:8px;
                                font-size:11px;
                                line-height:13px;
                                color:#666;
                            }
                        }  
                    }
                    
                    &.ch1 {
                        margin:-40px -8px 0;
                        > li {
                            width:50%;
                            margin-top:0;
                            padding:40px 8px 0;
                            border:0;
                            a {
                                display:block;
                                .icon {
                                    display: block;
                                    width: 80px;
                                    height: 80px;
                                    margin: 0 auto 16px;
                                }
                            }
                            .tit {
                                font-size: 14px;
                                line-height: 22px;
                            }
                        }
                    }
                }
            }
        }        
        &.service-menu {
            margin-top:40px;
            padding-top:40px;    
        }
        &.consulting-menu {
            margin-top:24px;
            padding-top:0;
            border-top:0;
            .banner {
                .bullet-list {
                    margin-top:0;
                    .b-txt {
                        font-size:13px;
                        line-height:1.31;
                    }
                    + .service-list-wrap {
                        margin-top:16px;
                    }
                }   
            }
        }  
        // BTOCSITE-55571 스스로 해결 콘텐츠 Survey 화면 변경 및 데이터 Tracking
        // 스스로해결 상세
        &.type-col5 {
            .service-list-wrap {
                .service-list {
                    > li {
                        background-color: white;
                        .tit {
                            margin-left: 20px;
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 22px;
                            text-align: left;
                            .icon-arrow {
                                display:inline-block;
                                width:16px;
                                height:16px;
                                margin-left:4px;
                                background:url(/lg5-common/images/icons/btn-arr-24x24-black.svg) no-repeat;
                                background-size:100% 100%;
                                vertical-align:-3px;
                            }
                        }
                    }
                }
            }
        }
    }
    /* 제품사용 설명서 & 드라이버/SW 재정의 */
    .download-list-wrap {
        margin-top: 24px !important;
        .download-list {
            > li {
                float:none !important;
                width:100% !important;
                border:1px solid #ddd;
                border-radius:8px !important;
                box-shadow:none !important;
                &:first-of-type {
                    margin-top:0;
                }
            }
            .info-wrap {
                .btn-wrap {
                    margin-top:12px !important;
                    .btn-newwin {
                        display:inline-block;
                        padding-right:20px;
                        margin:0 8px;
                        font-size:13px;
                        line-height:1.2em;
                        background:url('/lg5-common/images/CS/icon/icon_blank_16.svg') no-repeat 100% -1px / 16px;
                    }
                    .btn-download {
                        display:inline-block;
                        padding-right:20px;
                        margin:0 8px;
                        font-size:13px;
                        line-height:1.2em;
                        background:url('/lg5-common/images/CS/icon/icon_download_32.svg') no-repeat 100% -1px / 16px;
                    }
                }
            }
            .options {
                > li {
                    &:after {
                        display:none !important;
                    }
                    &:first-of-type {
                        &:before {
                            display:none;
                        }        
                    }
                    &:before {
                        content: '';
                        display: inline-block;
                        width: 1px;
                        height: 10px;
                        margin: 7px 8px 0;
                        background: #ddd; 
                    }
                    &:nth-child(2) {
                        margin-right:0 !important;
                    }
                }
            }
        }
        > .desc {
            margin-bottom:16px !important;
        }
    }
    /* 드라이버/SW */
    .driver-section {
        .form-wrap {
            margin-bottom:24px !important;
            .forms {
                float:none !important;
                width:100% !important;
            }
        }

    }
    .survey-banner-wrap {
        .banner {
            & > p {
                padding:56px 0 0 !important;
                &:before {
                    width:40px !important;
                    height:40px !important;
                    background-image:url(/lg5-common/images/CS/icon/icon_evaluation_80px.svg) !important;
                }
            }
            .btn-group {
                margin-top:16px !important;
                .btn {
                    width:auto;
                    background:#FFF;
                }
            }
        }
        &.renew {
            padding:24px 24px 32px !important;
            .banner {                
                margin:0 !important;
                border-bottom:0 !important;
            }
            .btn-area {
                margin-top:12px !important;
                .btn {
                    width:100%;
                    padding:12px 16px !important;
                    background:#FFF;
                    font-size:14px !important;
                }
                
            }
            .form-wrap {
                .forms {
                    .conts {
                        margin-top:16px;
                    }
                }
            }
        }
    }

    // BTOCSITE-43111 철거/이동/재설치 안내
    .inst-move-info {
        margin-top: 32px;
        padding: 0 16px;
        p {
            padding-left: 20px;
            line-height: 26px;
            text-indent: -20px;
        }
        .btn-link {
            position: relative;
            top: 2px;
            text-indent: 0;
            margin-left: 5px;
        }
    }
    // BTOCSITE-69770 서비스예약시, 폭염특보 안내문 반영의 건
    .inst-move-info-aircon{
        p {
            margin: 0 16px;
            padding-left: 20px;
            line-height: 26px;
            text-indent: -20px;
        }
    }



} //support
@media screen and (min-width: 768px) {
	.support {
        .survey-banner-wrap {
            &.renew {
                &.type2 {
                    .banner {
                        padding:0 !important;
                        .btn-group {
                            margin-top:0 !important;
                        }                        
                        & > p {
                            padding: 0 0 0 60px !important;
                        }
                    }
                    .form-wrap {
                        .forms {
                            margin-top:0 !important;
                        }
                    }
                }
            }
        }
    } 
    // BTOCSITE-55571 스스로 해결 콘텐츠 Survey 화면 변경 및 데이터 Tracking
	// .support .survey-banner-wrap.renew.type2 .form-wrap .forms .tit label,
	// .support .survey-banner-wrap.renew.type2 .form-wrap .forms .tit .label {
	// 	font-size: 22px !important;
	// 	font-weight: 500;
	// }
	.support .survey-banner-wrap.renew.type2 .form-wrap .forms {
		margin-top: 54px;
	}
}

//BTOCSITE-69159 고객지원 > 출장예약 > 에어컨 > 설치장소 추가
#bdTypePopup {
    .common-pop {
        .text-area {
            padding: 0 0 0;
            line-height: 1.4;
            margin-bottom: 16px;
            border-radius: 6px;
        }
        .rdo-list-wrap {
            background-color: #f8f8f8;
            .rdo-list {
                margin: 0;
                display: flex;
                flex-direction: column;
                margin: 0 16px;
                padding: 0;
                li {
                    width: 100%;
                    padding: 16px 6px;
                    &:first-child {
                        border-bottom: 1px solid #dddddd;
                    }
                }
                .desc {
                    color: #666;
                    font-size: 12px;
                    line-height: 1.4;
                    margin-top: 14px;
                    word-break: break-word;
                }
            }
        }
    }
}


// BTOCSITE-13601 모델명 확인 방법 > 이미지 확대 창에 닫기 버튼 추가 요청
.popup-wrap {
    /* 재정의 2023.05.10 */
    // .pop-header {
    //     .tit {
    //         span {
    //             font-size:18px;
    //         }
    //     }
    // }
    .pop-conts {        
        /* 재정의 2023.04.24 */
        height:calc(100% - 140px);
        max-height:calc(100% - 140px);
        &.reservation-time-pop {
            padding-top:32px;
        }
        // BTOCSITE-13599 요금 및 보증기간 안내 팝업 수정 - 모델명 확인 이미지확대 선택 시 팝업
        .zoom-area {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            height: calc(100% - 62px);
            @include mobile {
                margin-top: 15px; //모바일해상도 작은사이즈에서 닫기버튼 겹치는 현상 수정
            }
            img {
                width: auto;
                max-width: 100%;
            }
        }

    }
    
    /* 재정의 2023.04.24 */
    .pop-footer {
        .btn-group {
            padding:14px 16px;
            background:#FFF;
            border-top:1px solid #DDD;
            .btn {
                width:calc(50% - 4px) !important;
                height:50px;
                margin-left:8px;
                padding:15px;
                border-radius:6px;
                font-size:14px;
                line-height:20px; 
                &:first-of-type {
                    margin-left:0;    
                }
                &:only-child {
                    width:100% !important;
                }   
            }    
        }
    }

    /* 재정의 2023.04.24 */
    &.small {
        .pop-conts {
            padding:32px 22px;
            // BTOCSITE-51736 실제 예약 시간 보다 더 빠른 연락을 원하는 고객 취향 반영
            .call-agree-input {
                margin-top: 20px;
                text-align: center;
            }
        }
        .pop-footer {
            .btn-group {
                border-top:0;
            }
        }
    }
    .btn-group {
        padding:0 15px 15px;
        border-top:0;
    } 
    /* 재정의 2023.04.24 */
    // &#reservationTimePopup {
    //     .pop-conts {
    //         .section {
    //             .tit-wrap {
    //                 margin-bottom:16px !important;
    //             }
    //         }
    //     }
    //     .calendar-area {
    //         .calendar-info {
    //             width:auto;
    //         }
    //     }
    // } 
    
    .common-pop {
        .form-wrap {
            &:first-child {
                .form-infos {
                    &:first-child {
                        margin-top:0;
                        + .forms {
                            margin-top:-24px;
                        }
                    }
                }
            }
            
        }
    }
}

// BTOCSITE-21589 출장서비스 신청 후 카톡 연결 링크 페이지 디자인 가이드 준수 요청 건
.win-popup-wrap  {
    .pop-footer {
        .btn-group {
            @include mobile {
                margin: 0;
                padding: 0;
            }
        }
    }
}
/* 정리 중 추가 금지 (꼭 필요할 경우에만 사용) */
// display
.pc-only {
    display: block;
    &.ib {
        display: inline-block;
    }
    @include mobile {
        display: none;
        &.ib {
            display: none;
        }
    }
}
.mo-only {
    display: none;
    @include mobile {
        display: block;
    }
}

// text-align
.align-left {
    text-align: left !important;
}
.align-right {
    text-align: right !important;
}
.align-center {
    text-align: center !important;
}

// color
.color-1 {
    color: #000 !important;
}
.color-2 {
    color: #f04e3e !important;
}
.color-3 {
    color: #ea1917 !important;
}
.color-4 {
    color: #008182 !important;
}

//section title
.section-title{
    .tit{
        @include font-large;
        margin-top:48px;
        margin-bottom:16px;
    }
}

@include mobile{
    .contents-inner.type2{
        padding:0 5%;
    }

    .section-title{
        .tit{
            margin-top:20px;
        }
    }
}

.update-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding:28px 49px 29px;
    background: #f4f4f4;
    border-radius:10px;

    p {
        font-size:16px;
        line-height:1.63;
    }

    .btn-link {
        font-size:14px;
    }

    @include mobile {
        flex-direction: column;
        align-items: flex-start;
        padding:22px 20px;

        .btn-link {
            margin-top:30px;
            font-size:13px;
        }
    }
}

.find-model-number {
    display: block !important;
    max-width:none;
    position:relative;

    .pop-conts.no-footer {
        max-height:none;
        height:auto;
        overflow:auto;
    }
}



// BTOCSITE-1198 챗봇 상담 메뉴 추가 요청
.chat-customer {
    display: flex;
    justify-content: space-between;
    margin-left: -24px;
    @include mobile {
        flex-wrap: wrap;
        justify-content: initial;
        padding: 0 16px;
    }
    .item {
        flex: 1;
        margin-left: 24px;
        padding: 30px 25px;
        border-radius: 8px;
        border: solid 1px #ddd;
        text-align: center;
        .title {
            position: relative;
            display: block;
            padding-top: 96px;
            @include mobile {
                padding-top: 63px;
                font-size: 15px;
            }
            &::before {
                content:"";
                position: absolute;
                top: 0;
                left: 50%;
                display: inline-block;
                width: 80px;
                height: 80px;
                transform: translateX(-50%);
                @include mobile {
                    width: 48px;
                    height: 48px;
                }
            }
            &.icon-chat::before {
                background: url(/lg5-common/images/CS/icon-chat.png) no-repeat 50% 50%;
                background-size: cover;
            }
            &.icon-kakao::before {
                background: url(/lg5-common/images/CS/icon-kakao.png) no-repeat 50% 50%;
                background-size: cover;
            }
        }
        .desc {
            margin: 14px 0 30px;
            font-size: 16px;
            line-height: 1.57;
            text-align: center;
            @include mobile {
                margin: 9px 0 18px;
                font-size: 14px;
            }
            .only_pc {
                @include tablet {
                    display: none;
                }
            }
        }
        @include mobile {
            flex: auto;
            padding: 24px 25px;
            & + .item {
                margin-top: 32px;
            }
        }
    }
    .point {
        color: $color-primary;
    }
    & ~ .bullet-list {
        &.top-border {
            margin: 40px 0 0;
            @include mobile {
                margin: 40px 16px 0;
            }
        }
    }
}
.chat-info-wrap {
    margin-top: 70px;
    text-align:center;
    @include mobile {
        margin-top: 40px;
    }
    .desc {
        margin-top: 6px;
        @include font-medium;
        @include mobile {
            margin-top: 4px;
        }
    }
    .desc-bold {
        margin-top: 6px;
        font-weight:700;
    }
    .desc-thin {
        margin-top: 10px;
        @include font-small;
    }
}

// BTOCSITE-8855 s
.inquiry-sections{
    .faq-link{
        margin-top: 33px;
        a {
            img {
                width:100%;
                height:auto;
                &.mobile-only{
                    display: none;
                    @include mobile {
                        display: block;
                    }
                }
                &.pc-only{
                display: block;
                    @include mobile {
                    display: none;
                    }
                }
            }
        }
    }
}
// BTOCSITE-8855 e

/* 2023.04.17 Add */
.btn-bottom {
    position:fixed;
    bottom:0;
    left:0;
    right:0;
    z-index:99;
    width:100%;
    padding:14px 16px;
    background:#FFF;
    border-top:1px solid #DDD;
    font-size:0;
    .btn {
        width:calc(50% - 4px);
        height:56px;
        margin-left:8px;
        padding:15px;
        border-radius:6px;
        font-size:14px;
        line-height:1.43;   
        &:only-child {
            width:100%;
        } 
        &:first-of-type {
            margin-left:0;    
        }        
        &:disabled {
            background:#999;
            border-color:#999;
            color:#bbb;
        }
        &.solid {
            background:#ea1917;
        }
        &.gray {
            background:#e4e4e4;  
        }
    } 
    &:after {
        display:block;
        clear:both;
        content:'';
    }   
}
/* 2023.04.17 prod-selected-wrap */
.prod-selected-wrap {
    background:#f8f8f8;
    border-top:1px solid #ddd;
    .box {
        display:flex;
        align-items:center;
        justify-content:space-between;
        padding:18px 20px;
        gap:0 8px;
        .flag-wrap.bg-type .flag {
            height:20px;
            margin-right:6px;
            padding:0 6px;
            background:#FFF;
            border-color:#AAA;
            line-height:18px;
        }
        .prod-info { 
            flex:1;               
            .product {
                text-align:left;
                li {
                    font-weight:500;
                    font-size:14px;
                    line-height: 1.42;
                    color:#000;
                }
                li + li{
                    margin-left:1px;
                    padding-left:17px;
                    &:before {
                        top:2px;
                        background-image:url(/lg5-common/images/icons/btn-arr-24x24-black.svg);
                    }
                }
            }
            .tit {
                font-weight:500;
                font-size:14px;
                line-height: 1.42;
                color:#000;  
            }
        }
        .prod-btn {
            //flex:1;
            margin-top:0;
            text-align:right;
            .btn {
                position:relative;
                min-width:auto;
                padding:0 0 0 20px;
                border:0;
                font-size:14px;
                line-height:20px;
                color:#333;
                text-decoration:underline;
                background:none;
                &:before {
                    content:'';
                    display:inline-block;
                    position:absolute;
                    left:0;
                    top:3px;
                    width:16px;
                    height:16px;                       
                    background:url(/lg5-common/images/icons/icon-reset-32.svg) no-repeat 0 0 / 16px;
                }
            }
        }
    }
}

/* keyword-search */
.btn-search {background-image:url('/lg5-common/images/icons/icon-search-32.svg');}
.keyword-search {
    &.type2 {
        .search-box {
            .search-btn {
                margin-top:24px;
                text-align:center;
                .btn {
                    &.search {
                        padding-left:38px;
                        background-image:url(/lg5-common/images/icons/icon-search-20.svg);
                        background-color:#FFF;
                        background-repeat:no-repeat;
                        background-position:17px center;
                        background-size:16px 16px; 
                        &:hover, &:focus, &:active {
                            background-image:url(/lg5-common/images/icons/icon-search-20.svg);
                            color:#222;
                            border-color:#222 !important;
                        }
                    }
                }
            }
            .search-more {
                position:static;
                margin-top:24px;
                text-align:center;
                .btn-link {
                    padding-right:16px;
                    font-size:12px;
                    line-height:17px;
                    text-decoration:none;
                    &:after {
                        top:3px;
                        width:12px;
                        height:12px;
                    }
                }
            }
        }
    }  
    .search-box {
        width:100%;
    }
    .search-input {
        width:100%;
    }
}
.support .solutions-wrap .section .search-wrap {
    padding-top:20px;
    padding-bottom:23px;
}
.support .solutions-wrap .keyword-search .search-input input[type=text] {
    box-shadow:none;
    border:1px solid #929292;
}

/* ui-select-btn 2023.04.18 Add */
.select-wrap {
    .ui-selectbox-wrap {  
        display: block;
        position: relative;  
        .ui-select-button {
            display:block;
            width:100%;
            height:46px;
            padding:11px 40px 11px 16px;
            // background:#FFF;
            // border:1px solid #929292;
            // border-radius:6px;
            text-align: left;
            .ui-select-text {
                display:inline-block;
                overflow:hidden;
                max-width:100%;
                color:#666;
                font-size:14px;
                line-height:20px;
                text-overflow:ellipsis;
                white-space:nowrap;
                word-break:break-all;
                vertical-align:top;  
                &.selected {
                    color:#000;    
                }              
            }
            .ico {
                position: absolute;
                top:50%;
                right:15px;
                width:16px;
                height:16px;
                margin-top:-8px;
                background:url(/lg5-common/images/icons/btn-down-16-black.svg) no-repeat 0 0;
                transition:all 0.3s;
            }
        }    
    }
    &.type-normal {
        display:inline-block;
        width:auto;
        vertical-align: middle;
        .ui-select-button {
            width:auto;
            height:auto;
            padding:0 20px 0 0;
            border:none;
            border-radius:0;
            .ico {
                right:0;
            }
        }
    }
}
.sort-box .select-wrap {margin-left:20px;}
.form-wrap .forms .conts .select-wrap {margin-top:8px;}

/* bottom-sheet-modal 2023.04.18 */
.bottom-sheet-modal {
    position:fixed;
    top:0;
    bottom:-110%;
    left:0;
    right:0; 
    inset:0px;
    z-index:9003;
    width:100%;
    height:0;
    .bottom-sheet-dim {
        position:fixed;
        top:0;
        bottom:0;
        left:0;
        width:100%;
        height:100%;
        background:rgba(0, 0, 0, 0.7);
        transition: all 0.1s ease-in-out;
        opacity:0;
        pointer-events: none;        
    } 
    .bottom-sheet {
        position:absolute;
        bottom:-110%;
        left:0;
        right:0;
        width:100%;
        z-index:9002;        
        background:#FFF;
        border-radius:8px 8px 0 0;
        transition: all 0.2s ease-in-out;
        .bottom-sheet-header {
            position:relative;
            padding:16px 16px 12px;
            .bottom-sheet-title {
                display:block;
                font-weight:700;                
                font-size:15px;
                line-height:22px;
                color:#000;  
                text-align:left;
            }
            .bottom-sheet-close {
                position:absolute;
                top:50%;
                right:16px;
                transform:translateY(-50%);
                width:16px;
                height:16px;
                margin-top:2px;
                &:before {
                    position:absolute;
                    top:0;
                    left:0;
                    width:16px;
                    height:16px;
                    background:url(/lg5-common/images/icons/btn-down-16-black.svg) no-repeat;
                    background-size: 16px;                    
                    content: ' ';
                }
            }
        }        
        .bottom-sheet-selectbox { 
            padding:0;
            .bottom-sheet-scroll {                               
                min-height:147px;
                max-height:441px;
                overflow-y:auto;
                @media screen and (orientation:landscape) {
                    max-height:50vh;
                 }
            }
            li {
                a {
                    display:block;
                    padding:12px;
                    font-weight:400;                    
                    font-size:14px;
                    line-height:22px;                    
                    color:#000;
                    text-align:center;
                    word-break: break-all;
                }
                &.on {
                    background:#f4f4f4;
                    a {
                        font-weight:bold;
                        color:#EA1917;
                    }
                }
            }
        }
    } 
    &.on {
        bottom:0;
        height:100%;
        .bottom-sheet-dim {
            opacity: 1;
            pointer-events: all;            
            z-index:9001;
        }
        .bottom-sheet {
            bottom:0; 
        } 
    }
}

/* accordion-border-wrap round-box 2023.04.18 */
.accordion-border-wrap {
    &.round-box {
        > ul {
            > .lists {
                border:0;
                &:first-child {
                    border:0;
                }
                .head {
                    .accord-btn {
                        padding:13px 12px;
                        background-color:#f8f8f8;
                        border-radius:8px;
                        .title {
                            font-weight:normal;
                            font-size:14px;
                            line-height: 1.57;
                        }
                        &:after {
                            background:none;
                            content: " ";
                            position: absolute;
                            top:50%;
                            right:20px;
                            width: 0;
                            height: 0;
                            margin-top: -4px;
                            border-top: 6px solid #979797;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            transition: border-color 0.3s, transform 0.3s;
                            transform:rotate(0deg);
                        }
                    }
                }                
                .accord-cont {
                    padding:0 0 12px 0;
                    .box {
                        padding:0;
                        background:none;
                    }
                }
                &.on {
                    background-color:#f8f8f8;
                    border-radius:8px;  
                }
            }
        }
        .service-guide-wrap {
            margin-bottom:0;
            .bullet-list {
                margin-top:12px;
            }
        }
    }
    + .btn-group {
        margin-top:20px;
    }
}
.service-guide-wrap {
    .box {
        .bullet-list {
            text-align:left;
        }
    }
}

/* inquiry-list column */
.inquiry-list {
    .description {
        .point {
            color:#EA1917;
        }
    }
    &.column {
        > li {
            flex-direction: column;            
            > .title {
                display:flex;
                align-items:center;
                justify-content:space-between;
                width:100%;
                font-weight:700;
                font-size:18px;
                line-height:1.22;
                color:#000;
            }
            > .description {
                width:100%;
                margin-top:13px;
                li {
                    display:flex; 
                    margin-top:12px; 
                    &:first-of-type {
                        margin-top:0;    
                    }
                }
                .title {
                    font-weight:normal;
                    font-size:14px;
                    line-height:1.57;
                    color:#666;
                }
                .description {
                    font-weight:500;
                    font-size:14px;
                    line-height:1.57;
                    color:#000;
                }
            }
        }
    }
    &.type2 {
        > li {
            > .title {
                font-size:14px;
                line-height:22px;
            } 
            > .description {
                font-size:14px;
                line-height:22px;
            }
            &:last-child {
                margin-bottom:20px;
            } 
        }
    }
}


// 챗봇상담
.banner-chat-wrap {
    margin: 32px 0;
    .banner {
        padding:24px;
        background-color: #F6F6F6;
        border-radius: 8px;
        .cont {
            .tit {
                font-weight: 700;
            }
        }
    }
    .btn-group {
        margin-top: 12px !important;
        padding: 0;
        .btn-chatbot {
            width: 100%;
            height: 32px;
            color: $color-primary;
            border: 1px solid $color-primary;
            background-color: transparent;
            &::before {
                content: '';
                position: relative;
                top: -2px;
                display: inline-block;
                width: 16px;
                height: 16px;
                border-radius: 32px;
                margin-right: 6px;
                vertical-align: middle;
                background: url('/lg5-common/images/CS/btn-chat-icon.svg');
                background-size: 100% auto;
            }
        }
    }

    .qna-list {
        margin-top: 24px;
        dl {
            dt {
                font-size: 13px;
                line-height: 20px;
                color: #0F0F0F;
                &::before {
                    content: '';
                    position: relative;
                    top: -2px;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    margin-right: 6px;
                    vertical-align: middle;
                    background: url('/lg5-common/images/CS/icon-qna.svg');
                    background-size: 100% auto;
                }
            }
            dd {
                position: relative;
                display: inline-block;
                font-size: 12px;
                line-height: 18px;
                padding: 8px 12px 10px 33px;
                background-color: #FFF;
                color: #000;
                border: 1px solid #EEE;
                border-radius: 8px;
                margin-top: 6px;
                button {
                    font-size: 12px;
                    line-height: 18px;
                    text-align: left;
                    max-height: 36px;
                    text-overflow: initial;
                    white-space: normal;
                    @include textEllipsisClamp(2);
                    &::after {
                        content: '';
                        display: inline-block;
                        width: 14px;
                        height: 14px;
                        margin-left: 4px;
                        background: url("/lg5-common/images/icons/arr-32x32.svg") no-repeat 0 0;
                        background-size: 100%;
                        position: relative;
                        top: 3px;
                        opacity: 0.8;
                    }
                }
                &::before {
                    content: 'Q';
                    position: absolute;
                    display: inline-block;
                    left: 16px;
                    top: 8px;
                    font-size: 14px;
                    line-height: 20px;
                    color: $color-primary;
                    font-weight: bold;
                }
                
            }
        }
    }
}

.banner-wrap {
    margin:32px 0;
    .banner {
        padding:24px;
        .cont {
            .tit {
                &.fwn {
                    font-weight:normal;
                    + a {
                        display:inline-block;
                        margin-top:10px;
                        font-size:13px;
                    }
                }
            }
        }
        .keyword-search {
            &.type2 {
                .search-box {
                    .search-more {
                        .btn-link {
                            margin-top:0;
                        }
                    }
                }
            }
        }
    }
    .btn-group {
        display:flex;
        align-items:center;
        justify-content:space-between;
        margin-top:24px;
        padding:0;
        .btn {
            width:auto;
        }
        .btn-link {
            margin-top:0;
        }
        &.center {
            justify-content:center;    
        }
    }
    @media screen and (min-width:768px) {
        .banner {
            .cont {
                padding-right:0;
            }
            .btn-group {
                margin:0;
            }
        }
    }
}

/* new info-update-warp */
.info-update-warp {
    .cont {
        .title {
            font-weight:700;
            font-size:16px;
            line-height:1.38;
            text-align:center;
        }
    }
    .btn-group {
        display:flex;
        flex-wrap:wrap;
        justify-content: center;
        align-items: center;
        align-content: space-between;
        gap:12px;
        margin-top:24px;
        padding:0;
        text-align:center;
        .inpBox {            
            label {
                display:inline-block;
                height:35px;
                padding:0 16px;
                line-height:34px;
                border-radius:17.5px;
                border:1px solid #222;
                font-size:13px;
                color:#000;
                cursor:pointer;
            }
            input:checked + label {
                background:#ea1917;
                border-color:#ea1917;
                color:#fff;
            }
        }
    }
}

/* calendar-area */
.calendar-area {
    &.type2 {
        .calendar-info {
            position:static;
        }
    }
    /* 재정의 */   
    .calendar-info {
        position:absolute;
        top:0;
        right:0;
        margin:0;
        .info {
            margin-top:0;
        }
    } 
    .calendar-box {
        .box-inner {
            .box-tit {
                .tit {
                    font-size:14px;
                }
            }
            table {
                &.tb-timetable {
                    tr {
                        th, td {
                           // padding:18px 7px 0;        
                        }
                        &:first-of-type th, &:first-of-type td {
                           // padding-top:0;    
                        }
                    }
                    

                } 
            }
        }
        &.time {
            margin-top:32px;
        }
    }
    .date-wrap {
        height:auto;
        padding:24px 28px;
    }
    .time-wrap {
        height:auto;
        padding:16px 8px 16px; 
        
        &.type2 {
            padding:24px 21px; 
            table {
                &.tb-timetable {
                    tr {
                        th, td {
                            padding:18px 7px 0;        
                        }
                        &:first-of-type th, &:first-of-type td {
                            padding-top:0;    
                        }
                    }
                    

                } 
            } 
        }
    }
    //BTOCSITE-64827 고객지원 > 출장예약 > 모바일 > 날짜/시간 선택 UI 변경
    .calendar-popup-wrap {
        margin-top: 12px;
    }
} 
@media screen and (min-width: 1285px) {
    .calendar-area {
        display:block;
    }    
}


/* 예약조회 및 변경 */
.service-status {
    .login-guide {
        margin-top:20px;
    }    
    .cont-wrap {
        padding-top:0px !important;  
        .section {
            .tit-wrap {
                .tit {
                    margin-top:16px;
                }
            } 
        } 
    }
    .form-infos {
        margin-top:16px;
        margin-bottom:0px;
    }
    .bullet-list {
        &.top-border {
            margin-top:40px;
            padding-top:15px;
        }        
    }
}

/* btn-forms-search */
.btn-forms-search {
    margin-top:20px;
    text-align:center;
    .forms-search {
        width:100%;
        padding:12px;
        background:#ea1917;
        border:1px solid #ea1917;
        border-radius:6px;
        font-size:14px;
        line-height:20px;
        color:#FFF;        
    }
}

// 출장예약완료
.page-header.reservationComplete {
    @include pc {
        padding-bottom:32px;
    }
	.title {
		display: flex !important;
        align-items:center;
        justify-content:center;
        margin-top:16px !important;
        font-weight:normal;
        font-size:16px;
        line-height:1.4;
		i {
			display: block;
			width:32px;
			height:32px;
			margin:0 6px 0 0;
			background: url('/lg5-common/images/icons/icon-complete-64.svg') no-repeat 50% 0 / 32px;
		}
	}
}
.support {
	.complete-summary {
		margin: 20px 0 36px;
		padding: 0 16px;
        background-color:transparent !important;
		text-align: center;
        @include pc {
            margin-top:0;
        }
		.summary-img {
            display:block !important;
            max-width:none !important;
            margin:0 auto !important;
            width:auto !important;
			img {
                width:auto !important;
				height:120px !important;
				border-radius: 16px;
			}
		}
		.summary-info {
            display:block !important;
			margin-top: 16px;
            padding-left:0 !important;
			line-height: 21px;
            .status {
                font-size:13px;
            }
            &-type2 {
                padding:20px;
                border-radius:8px;
                background:#f4f4f4;
                text-align:left;
                .manager {
                    font-size:15px;
                }
                .status {
                    font-size:14px;
                    line-height:20px;
                    word-break:keep-all;
                }
            }
		}
        .btn-wrap {
            margin-top:24px;
            .btn + .btn {
                margin-left:3px;
            }
        }
	}
    .reservation-summary {
        padding-top:24px;
        border-top:1px solid #666;
    }
    .reservation-summary2 {
        padding-top:16px;
    }
}
.inquiry-list {
	.title {
		strong {
            font-size:14px; 
			font-weight: 400;
			color: #666;
            @include pc {
                font-size:16px;
            }
		}
	}
	.description {
		font-weight: 400;
	}
}
.bullet-list.top-border {
	margin-top: 40px;
}
.bottom-banner {
	margin-top: 40px;
    padding: 0 16px;
	a {
        position:relative;
		display: block; 
		width: 100%;
		height: 0;
		padding-left: 6%;
        padding-top: 26%;
		border-radius: 8px;
		background-repeat: no-repeat;
		background-position: 70% 0;
		background-size: cover;
		.txt {
            position:absolute;
            left:6%;
            top:50%;
			font-size: 3.2vmin;
			font-weight: 700;
			line-height: 1.4em;
            transform:translateY(-50%);
		}
	}
    + .btn-group {
        margin-top:40px;
    }
}

// 출장예약조회
.container .page-header.reservationStep {
    padding:20px 0 0;
    text-align:left;
    .tit {
        font-size:18px;
    }
    .tit-small {
        margin-top:20px;
        font-size:16px;
    }
    .step-info {
        margin:13px 0 8px;
        font-size:13px;
        span.on {
            font-weight:700;
            color:#ea1917;
        }
    }
}

/* 출장서비스 */
.service-engineer {
    padding-bottom:85px;
    .cont-wrap {
        padding-top:0 !important;
        .section{
            .tit-wrap {
                .tit { 
                    margin-top:16px;   
                }
            } 
        } 
    }

    //BTOCSITE-61349  고객지원 > 출장예약 > 구매일 정보 간소화
    #stepModel2 {
        //BTOCSITE-69400 고객지원 > 출장예약 > 구매정보 입력 단계 수정 및 스타일러 도어 대응
        // .section + .section {
        //     margin-top: 32px !important;
        //     border-top-width: 10px !important;
        // }
        .hideBox ~ #fanBox,
        .hideBox ~ #tvPositionBox,
        .hideBox ~ #installTypeBox {
            margin: 0;
            border: 0;
            padding-top: 0;
        }
    }
    
    //BTOCSITE-65147 고객지원 > 출장예약 > 세척서비스 추가
    #stepModel3 {
        .clean-banner-wrap {
            position: relative;
            .img-wrap {
                height: 118px;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .item-wrap {
                position: absolute;
                display: flex;
                top: 0;
                width: 100%;
                height: 100%;
                padding: 30px;
                align-items: center;
                .item {
                    display: flex;
                    width: 100%;
                    align-items: flex-end;
                    justify-content: space-between;
                    .text-wrap {
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 25px;
                        color: #ffffff;
                        span{
                        font-weight: 500;
                        }
                    }
                    .banner-button {
                        padding: 10px 12px;
                        background-color: #EA1917;
                        color: #ffffff;
                        border-radius: 18px;
                        font-size: 12px;
                        font-weight: 700;
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    .info-list {
        margin-top: 30px;
        padding: 0 10px;

        .tit {
            font-size: 14px;
            font-weight: bold;
        }
        .bullet-list {
            margin-top: 10px;
        }   
    }

}
.bullet-list {
	.b-txt {
		font-size:12px;
	}
}
.popup-search {
    .pop-conts {
        height:100%;
        max-height:100%;
        padding-top:54px;
    }
    .tit {
        padding:0 4px;
        font-weight:bold;
        font-size:18px;
        line-height:1.33;
    }
    .keyword-search {
        margin-top:20px;
        .search-input {
            input[type=text] {
                padding:8.5px 80px 9.5px 20px;
                background:#f4f5f7;
                box-shadow:none;
            }
        }
        .search-info {
            padding:20px;
            font-size:13px;
            line-height:19px;
            color:#666;
        }
        
        &.on {
            .search-layer {
                position:absolute;
                top:39px;
                min-height:calc(100vh - 170px);
                max-height:calc(100vh - 170px);
                padding:16px 20px;
                box-shadow:none;
                border-radius:0;
                .autocomplete-box {
                    .keyword-list {
                        ul {
                            margin:0;
                            li {
                                position:relative;
                                margin-top:0;
                                padding:0;
                                border-radius:4px;
                                margin-top:16px;
                                // 모델명 검색
                                // &:hover { 
                                //     &:after {
                                //         position:absolute;
                                //         top:50%;
                                //         right:10px;
                                //         width:16px;
                                //         height:16px;  
                                //         transform:translateY(-50%);                                 
                                //         background:url("/lg5-common/images/icons/ic_check_on_32.svg") no-repeat 0 0; //이미지 없음
                                //         background-size:16px;
                                //         content:'';
                                //     }
                                // }
                                &:first-child {
                                    margin-top:0;
                                }
                            }
                        }
                        .no-keyword {
                            font-weight:normal;
                            font-size:13px;
                            line-height: 1.46;
                            color: #000;
                        }
                    }
                }
            }
        }
    } 
    .btn-search {
        width:47px;
        height:40px;
    }
    .btn-delete {
        right:48px;
    }
    .pop-footer {
        .btn-group {
            position: fixed;
            bottom:0;
            left:0;
            width:100%;
            padding:14px 16px;
            background:#FFF;
            border-top:1px solid #DDD;
            .btn {
                height:56px;
                padding:15px;
                border-radius:6px;
                font-size:14px;
                line-height:1.43;    
            }    
        }
    }
    .recent_search_word {
        margin-top:44px;
        .tit {
            position:relative;
            .del_all {
                position:absolute;
                right:0;
                top:2px;
                font-size:13px;
                font-weight:400;
                text-decoration:underline;
            }
        }
        ul {
            margin-top:22px;
            li {
                position:relative;
                display:inline-block;
                margin:0 12px 16px 0;
                &:last-child {
                    margin-right:0;
                }
                a {
                    display:block;
                    height:40px;
                    padding:0 40px 0 16px;
                    line-height:40px;
                    border-radius:20px;
                    font-size:14px;
                    background:#f4f4f4;
                    max-width: calc(100vw - 40px);
                    @include textEllipsis;
                }
                button {
                    position:absolute;
                    right:8px;
                    top:4px;
                    z-index:2;
                    width:32px;
                    height:32px;
                    background:url('/lg5-common/images/icons/btn-clear-16.svg') no-repeat 50% 50% / 16px;
                }
            }
        }
    }
    .popular_search_word {
        margin-top:30px;
        .tit {
            position:relative;
            .del_all {
                position:absolute;
                right:0;
                top:2px;
                font-size:13px;
                font-weight:400;
                text-decoration:underline;
            }
        }
        ul {
            margin-top:22px;
            li {
                display:inline-block;
                margin:0 12px 16px 0;
                &:last-child {
                    margin-right:0;
                }
                a {
                    display:block;
                    height:40px;
                    padding:0 16px;
                    line-height:40px;
                    border-radius:20px;
                    font-size:14px;
                    background:#f4f4f4;
                }
            }
        }
    } 
    @media screen and (min-width:768px) {
        height:80vh;
    }
}
.keyword-search .search-layer .keyword-list>ul>li a {
    width:100%;
    max-width:100%;
    text-overflow: initial;
    white-space: normal;
    line-height: 1.6;
    .list-desc {
        @include textEllipsisClamp(2)
    }
}
.keyword-search .search-layer .keyword-list .no-keyword {
    font-size:13px;
    text-align:left;
    line-height:1.6;
    //color:#ff3723;
    &:before {
        content:'!';
        display:inline-block;
        font-size:10px;
        width:14px;
        height:14px;
        margin-right:4px;
        border:1px solid #ff3723;
        border-radius:50%;
        vertical-align:middle;
        line-height:1;
        text-align:center;
    }
    &.type2 {
        &:before {
            display:none;
        }
    }
}
.popup-search .keyword-search.on .search-layer .autocomplete-box .keyword-list .no-keyword {
    text-align:left;
}

#buildingType, #bdTypeBox {
    .rdo-list {
        li:nth-of-type(2) {
            label {
                .tooltip-box {
                    left:auto;
                    right:0;
                }
            }
        }
    }
}

/* .tabs-wrap-new */
.tabs-wrap-new {
    > .tabs {
        li {
            padding:0;
            > a {
                padding:17px 12px 0;
            }
        }
    }
    &.grid2 {
        .tabs {   
            width:100%;         
            padding:0 16px;
            > li {
                width:50%;
                > a {
                    width:100%; 
                    text-align:center;   
                }
            }
        }
    } 
    &.grid3 {
        .tabs {   
            width:100%;         
            padding:0;
            > li {
                width:33.33%;
                > a {
                    width:100%; 
                    text-align:center;   
                }
            }
        }
    }    
}

//BTOCSITE-64829 고객지원 > 출장예약 > 스스로 해결 콘텐츠 제공
#solutionsPopup {
    .pop-footer {
        position: absolute;
        left:0;
        right: 0;
        bottom: 0;
    }
}
#ratesWarrantyGuidePopup {
    .pop-conts {
        position:relative;
        padding-top:10px;
        height: calc(100% - 40px);
        max-height: calc(100% - 40px);
    }
    .tooltip-wrap.share {
        top:100px;
        z-index:10;
    }
    .tooltip-box {
        padding-right:24px;
    }
}

/* pop-res-date */
.pop-res-date { 
    text-align:center;
    .res-info-date {
        padding-top:13px;
        line-height:22px;        
    }
    .res-info-addr {
        margin-top:13px;
        font-size:14px;
        line-height:20px;
        text-align:left;

        #fastDtAddr{
            word-break: break-word;
        }
    }
    
}

/* controls - switch */
.switch-wrap {
    display: inline-block;
    .label {
        font-size:14px;
        margin-right:6px;
        line-height:20px;
        color:#333;
        vertical-align:middle;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #8F8F8F;
        -webkit-transition: .4s;
        transition: .4s;
        &:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            left: 2px;
            bottom: 2px;
            background-color: #F7F7F7;
            -webkit-transition: .4s;
            transition: .4s;
        }
    }
    input:checked + .slider {
        background-color: #000;
    }
    input:focus + .slider {
        box-shadow: 0 0 1px #000;
    }
    input:checked + .slider:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }
}
.slider.round {
    border-radius: 24px;
    &:before {
        border-radius: 50%;
    }
}
.switch:has(input:disabled) {
    .slider {
        border: 1px solid #8f8f8f;
        background-color: #EAEAEA;
        &.round {
            &::before {
                border: 1px solid #8f8f8f;
                top: 1px;
                background-color: #F7F7F7;
            }
        }
    }
}
.switch:has(input[type="checkbox"]:checked:disabled) {
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #8F8F8F;
        -webkit-transition: .4s;
        transition: .4s;
        &:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            left: 2px;
            bottom: 2px;
            background-color: #F7F7F7;
            -webkit-transition: .4s;
            transition: .4s;
        }
        &.round {
            &::before {
                background-color: #F7F7F7;
            }
        }
    }
}

// .switch:has(input[type="checkbox"]:enabled:hover) {
.switch:has(input[type="checkbox"]:checked:hover) {
    .slider {
        &.round {
            &::before {
                border: 1px solid #8f8f8f;
                background-color: #F7F7F7;
            }
        }
    }
}

/* sorting-wrap */
.sorting-wrap {
    .sort-box {
        font-size:0;
        .switch-wrap {
            + .sort-select-wrap {
                float:none;
                vertical-align:middle;
            }
        }        
    } 
}

/* .sort-select-wrap 재정의 */
.sort-select-wrap {
    .ui-selectbox-wrap {
        .ui-select-button {
            padding-right:20px;
            .ui-select-text {
                font-size:14px;
                line-height:20px;
                color:#333;
            }
        }
    }
}

/* .pagination 재정의 */
.pagination {
    .page_num {
        a {
            color:#767676;
        }
    }
    .prev {
        &.disabled {
            background-color:#f4f4f4;
            border-color:#eee;
        }
    }
    .next {
        &.disabled {
            background-color:#f4f4f4;
            border-color:#eee;
        }
    }
    .first {
        &.disabled {
            background-color:#f4f4f4;
            border-color:#eee;
        }
    }
    .last {
        &.disabled {
            background-color:#f4f4f4;
            border-color:#eee;
        }
    }
}

/* 재정의 */
.list-wrap {
    .list {
        border-color:#000;
        > li {
            &.video-type {
                .item {
                    padding-right:0;
                    .tit {
                        display:inline-block;
                        position:relative;
                        max-width:100%;
                        padding-right:25px;
                    }
                    .icon-movie {
                        display:inline-block;
                        right:0; 
                        width:20px;
                        height:20px;
                        vertical-align:middle;
                        background-size:20px 20px;
                    }
                }
                
            }   
            .tit {
                white-space:normal;
                word-wrap:break-word;
                word-break:break-all;
            }
            .point {
                color:#ea1917;
            }
            .option-wrap {
                display:flex;
                justify-content:space-between;
                margin-top:6px;
                .desc, .options {
                    margin-top:0;
                    font-weight:normal;
                    font-size:11px;
                    line-height:13px;
                    color:#666;
                }
                .options {
                    .hits {
                        margin-right: 5px;
                    }
                }
                
                .desc {
                    width:50%;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    white-space:nowrap;
                }
            }
        }
    }
}

/* 스스로해결 상세 관련 정보 리스트 */
.board-related-list {
    margin:0 0 60px;
    padding:35px 16px 40px;
    border-top:10px solid #f4f4f4;
    border-bottom:10px solid #f4f4f4;
    .title_wrap {
        display:flex;
        align-items:center;
        justify-content:space-between;
        margin-bottom:14px;
        .title {
            font-size:16px;
            line-height:22px;
        }
        .btn-link {
            margin-top:3px;
            font-size:13px;
            color:#666;
            text-decoration:none;
        }
    }
    .list-wrap {
        margin-top:3px;
        .list {
            padding-top:3px;
        }
    }
}
/* 스스로해결 */
.board-view-wrap {
    padding-top:0;
    .board-view-head {
        position:relative;
        padding:32px 0 16px;
        .head-title {
            font-size:20px;
            line-height:24px;
        }
        .head-infos {
            margin-top:22px;
        }
        .share {
            position:absolute;
            right:0;
            bottom:10px;
        }
    }
    .board-view-sub {
        padding:0 0 4px;
        border-color:#666;
        .sub-infos {            
            li {
                display:inline-block;
                font-size:13px;
                line-height:22px;
                &:before {
                    display:inline-block;
                    width:1px;
                    height:10px;
                    margin:0 6px;
                    background-color:#ddd;
                    content:'';
                    
                }
                &:first-of-type {
                    &:before {
                        display:none;
                    }    
                }
            }
        }
    }
    .board-view-body {
        padding:40px 0;
        .view-content {
            img {
                border-radius:8px;
            }
        }
    }
}

/* 추천 키워드 */
.search-recommend {
    display:flex;
    margin-top:16px;
    padding:0 8px;
    .search-title {
        font-size:13px;
        line-height:20px;
        &:after {
            display:inline-block;
            width:1px;
            height:12px;
            margin-left:8px;
            background:#DDD;
            content:'';
        }
    }
    ul {
        overflow-y:auto;
        scrollbar-width:none;
        width:calc(100% - 38px);
        font-size:0;        
        white-space:nowrap;
        > li {
            display:inline-block;
            margin-left:8px;
            > a {
                font-size:13px;
                line-height:20px;
                color:#666;
            }
        }
        &::-webkit-scrollbar {
            display:none;
            width:0;
            height:0;
            background:transparent;
            appearance:none;
        }
    }
}

/*** 온라인 상담 ***/
/* 1. 이메일 상담 */
.email-inquiry {
    padding-bottom:85px;
    .cont-wrap {
        padding-top:0 !important;
    }
    .regNumberBox {
        .btn-link {
            font-size:13px;
            margin:10px 0 0 12px;
        }
    }
}
/* 2. 전화 상담 */
.request-call {
    padding-bottom:85px;
    .cont-wrap {
        padding-top:0 !important;
    }
}
/* 3. 원격지원 상담 */
.support {
    .remote-call {
        padding-bottom:85px;
        .cont-wrap {
            padding-top:0 !important;
        }
        .service-guide-wrap {
            padding:0;
            .box {
                box-shadow:none;
            }
        }
    }
}
// .support.remote-call .service-guide-wrap {
//     margin-top:40px;
//     padding:0;
//     border-top:1px solid #ddd;
//     .bullet-list {
//         margin-top:17px;
//     }
// }
/* 4. 영상통화 상담 */
.video-consulting {
    padding-bottom:85px;
    .cont-wrap {
        padding-top:0 !important;
    }
}
/* 5. 수정 상담 */


/* 파일첨부 */
.image-file-wrap {
    .file-item {
        width:104px;
        &.on {
            .file-image {
                .file-preview {
                    //BTOCSITE-77120 고객지원 > 출장예약 > 소음증상일 때 파일업로드 기능 추가
                    &.icon-mov {
                        background: none;
                        img {
                            display: none;
                        }
                        
                        &::after {
                            content: '';
                            display: inline-block;
                            width: 50px;
                            height: 50px;
                            position: absolute;
                            top: calc(50% - 25px);
                            left: calc(50% - 25px);
                            background:url(/lg5-common/images/icons/icon-complete-64.svg) no-repeat;
                            background-size:100%;
                            opacity: 0.7;
                        }
                    }
                }
            }
        }

        .file-image {
            margin-bottom:7px;
            .file-preview {
                width:104px;                
                height:104px;
                &.icon-mov {
                    background: url(/lg5-common/images/CS/icon/icon_addfile.svg) no-repeat;
                    background-size: 100%;
                }
            }
            .file-btns {
                .btn-upload {
                    background-image:url(/lg5-common/images/CS/icon/ic_addfile_24.svg); 
                    background-size:100%;  
                }
                .btn-del {
                    background-image:url(/lg5-common/images/CS/icon/ic_addfile_24.svg);
                    background-size:100%;
                    transform:rotate(45deg);
                }
            }
        }        
        .file-name {
            .name {
                line-height:20px;
                color:#666;
                &::-webkit-input-placeholder,
                &:-moz-placeholder,
                &:-ms-input-placeholder {
                    color:#666;
                }
                &::placeholder {
                    color:#666;
                    opacity:1;
                }
            }
        }
        + .file-item {
            margin-left:8px;
        }
    }
}
/* .lay-wrap */
.lay-wrap, 
.lay-wrap.type2 {
    .btn-wrap {
        &.laypop {
            padding:0 15px 15px;
            .btn {
                width: calc(50% - 4px) !important;
                margin-left: 8px;
                border-radius:6px !important;
                // &:first-of-type {
                //     margin-left: 0;
                // }
                &:only-child {
                    width: 100% !important;
                }
                @include mobile {
                    // width: 50% !important;
                    margin-left:0;
                }
                & + .btn {
                    margin-left: 8px;
                }
            }
        }
    }
    &.type2 {
        .btn-wrap {
            &.laypop {
                padding:0 15px 15px;
            }
        }
    }
}

/* z-index 수정 */
.mobile-status-bar {
    z-index:109;
}

/* 센터방문예약 */
.visit-center {
    padding-bottom:85px;
    .cont-wrap {
        padding-top:0 !important;
    }
    #stepCenter, #stepModel5 {
        .section {
            > .tit-wrap {
                margin-top:32px;
            }
        }
    }
    .center-info-wrap {
        margin-bottom:20px !important;
        padding:0 !important;
        background:#FFF !important;
        .info-list {
            font-size:0;
            li {
                display:inline-block;
                margin-top:0 !important;
                margin-right:12px !important;
                &:last-of-type {
                    width:100%;
                    // margin-top:2px !important;
                    // .value {
                    //     color:#666 !important;    
                    // }
                }
            }
            .key {
                color:#666 !important;
            }
        }
    }
}
// .find-store-wrap .map-container .store-list-wrap .sch-box .form-wrap .keyword-search {
//     margin-top:0;
// }

/* 센터찾기 */
.find-store-wrap {
    .map-container {
        .store-list-wrap {
            .list-wrap {
                padding:0 16px !important;
                .head-wrap {
                    padding-bottom:14px;
                    .tit {
                        font-size:14px;
                        font-weight:400;
                    }
                    .near {
                        font-size:14px;
                        strong {
                            color:#ea1917;
                            line-height:1.4em;
                        }
                    }
                    .desc {
                        margin-top:0;
                        padding-left:6px;
                        font-size:13px;
                        line-height:18px;
                        color:#000;
                        &:before {                            
                            display: inline-block;
                            width:2px;
                            height:2px;
                            margin-top:8px;
                            margin-left:-5px;
                            margin-right:5px;
                            border-radius: 50%;
                            background: #000;
                            vertical-align:top;
                            content: ' ';
                        }
                    }
                    .chk-wrap {
                        input[type=checkbox] + label {
                            position: relative;
                        }
                        input[type=checkbox] + label::after{
                            content: "";
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            border: 1px solid #aaa;
                            border-radius: 3px;
                            background-image: none;
                            position: absolute;
                            left: 0;
                            top:55%;
                            transform: translateY(-55%);
                        }
                        input[type=checkbox]:checked + label::after{
                            background-image: url(/lg5-common/images/bg-chk-on.svg);
                            background-color: #222;
                            background-size: 9px auto;
                            border-color: #222;
                        }
                        input[type=checkbox]:checked:disabled + label::after {
                            border-color: #e4e4e4;
                            background-color: #f4f4f4;
                            background-image: url(/lg5-common/images/bg-chk-default.svg);
                        }
                    }
                }
                .no-data.type-center {
                    .tit {
                        font-weight:700;
                        color:#000;
                    }
                    .ref {
                        margin-top:12px;
                        .red {
                            color:#EA1917;
                        }
                    }
                }
            }
            .sch-list {
                &.top-border {
                    border-top:1px solid #000;
                }
                .list-item {
                    padding-right:0 !important;
                    > li {
                        .store-info-list {
                            .info-wrap {
                                .tit-wrap {
                                    align-items:end;
                                    margin-bottom:8px;
                                    .name {
                                        line-height:23px !important;
                                    }
                                    .distance {
                                        font-size:14px;
                                        line-height:20px;
                                    }
                                }
                                .date-time {
                                    font-size:13px;
                                    line-height:21px;
                                    color:#EA1917;
                                    .color-gray {
                                        color:#767676;
                                    }
                                }
                                .addr {
                                    max-height:42px;
                                    margin-top:8px !important;
                                    padding-right:30px;
                                    font-size:13px !important;
                                    line-height:21px !important;
                                }
                            }
                        }
                    }
                    .btn-box {
                        margin:6px 0 0 0;
                        position:relative;
                        .btn-link {
                            line-height:20px;
                            color:#333;
                            text-decoration:none;
                        }
                        .res-link {
                            position:absolute;
                            bottom:-6px;
                            right:0;
                        }
                    }
                }
                
            }
            .sch-box {
                .tabs-contents {
                    padding-top:16px;
                }   
                .form-wrap {
                    .keyword-search {
                        margin-top:0;
                    }
                }
            }
            .view-info-btn {
                bottom:47px !important; 
                span {
                    line-height:20px !important;
                    &:before {
                        width:16px !important;
                        height:16px !important;  
                        vertical-align:-3px !important; 
                    }
                }   
            }
            .view-info-search {
                //BTOCSITE-79146 고객지원 > 센터방문예약> 프로세스 개선
                // position:absolute;
                // bottom:47px;
                // right:0;
                margin-top: 10px;
                text-align: right;
                .btn {
                    min-width:auto;
                    padding:0;
                    background:transparent;
                    border:0;
                    font-weight:500;
                    font-size:14px;
                    line-height:20px;
                    color:#000;
                    &:before {
                        display:inline-block;
                        margin:0 10px;
                        width:1px;
                        height:14px; 
                        background:#e4e4e4;
                        vertical-align:-2px;
                        content: "";                          
                    }
                    &:first-of-type {
                        &:before {
                            display:none;
                        }    
                    } 
                    span {
                        &:before {
                            display:inline-block;
                            width:16px;
                            height:16px;
                            margin-right:4px;
                            vertical-align:-2px;
                            content: "";
                        }
                    }
                    &.search-current {
                        span {
                            &:before {
                                background: url("/lg5-common/images/CS/icon/icon_current_gps_32.svg") no-repeat 0 0/100% 100%;
                            }
                        }

                    }
                    &.search-userAdress {
                        span {
                            &:before {
                                background: url("/lg5-common/images/CS/icon/icon_current_home_32.svg") no-repeat 0 0/100% 100%;
                            }
                        }
                    }
                }
            } 
        }        
        &.show-map {
            .store-map-con {
                z-index:1 !important;
            }
        }
    }
    .map-area {
        .info-overlaybox {
            .inner {
                border-radius:8px !important;
                .btn-group {
                    .btn {
                        background:#FFF !important;
                    }
                }
                .date-time {
                    margin-top:8px;
                    font-size:13px;
                    line-height:21px;
                    color:#EA1917;
                    .color-gray {
                        color:#767676;
                    }
                }    
                .adress {
                    margin-top:12px !important;
                    padding-right:20px;
                }
            }
            .tit-wrap {
                display:flex;
                justify-content:space-between;
                .name {
                    font-size:16px !important;
                    line-height:22px !important;
                }
                .distance {
                    font-size:14px;
                    line-height:20px;
                }
            }
        }
    }
    .section {
        .map-container {
            margin:0 -16px;
            .store-list-wrap {
                
                .sch-box {
                    //margin:0 !important;
                }
                .list-wrap {
                    //padding:0 !important;  
                }
            }
            &.show-map {
                .store-map-con {
                    //margin:0 -16px;
                }
            }
        }
    }    
}
// .find-store-wrap .map-container .store-list-wrap .list-wrap .no-data.type-center {
//     .tit {
//         font-weight:700;
//         color:#000;
//     }
//     .ref {
//         margin-top:12px;
//         .red {
//             color:#EA1917;
//         }
//     }
// }

/* 센터정보 상세 */
.store-info-wrap {
    padding-bottom:0 !important;
    .link-btn-wrap {
        .btn-toggle-sms {
            padding:3px 16px 3px 12px;
            background:#FFF !important;
            border: 1px solid #000 !important;
            border-radius:6px !important;
            font-weight:bold !important;
            font-size:12px !important;
            color:#000 !important;
        }
    }
    .cont-wrap {
        .headline {
            margin:0 16px 32px;
            padding:22px 0 14px;
            h1 {
                &.h1-tit {
                    font-size:18px;
                    line-height:26px;
                }
            }
        }
        .tit-wrap {
            .tit-info {
                h2 {
                    &.name {
                        font-size:20px !important;
                        line-height:24px;
                    }
                }
            }
            .tit-info-r {
                padding-bottom:0;
                .link-btn-wrap {
                    position:static !important;
                    a {
                        &.btn-store-consult {
                            height:auto;
                            padding:8px 16px 8px; 
                            background:#222 !important;
                            border-radius:20px;
                            font-weight:normal;
                            font-size:13px;
                            line-height:19px;
                            color:#FFF;
                        }
                    }
                }
                .tooltip-wrap {
                    margin-left:10px;
                }
            }
        }
        .btn-close {
            top:22px;
            right:16px;
            &:after, &:before {
                width:1px;
                height:22px;
                background:#000;
            }
        }
    }
    .detail-info-wrap {
        .right-box {
            padding:0 !important;
            .info-list {
                margin-bottom:60px !important;
                dl {
                    &:first-of-type {
                        padding-top:0;
                    }
                    dt {
                        font-weight:normal !important;
                        line-height:20px !important;
                        color:#666 !important;
                    }
                    dd {
                        font-weight:500;
                        .addr-info {
                            margin-top:6px;
                        }
                        .link-btn-wrap {
                            margin-top:12px;
                        }
                        .news-info {
                            font-weight:bold;
                            color:#ea1917;
                        }
                        .item {
                            li {
                                .btn-link {
                                    margin-left:0 !important;    
                                }
                            }
                        }
                    }
                }
                .h3-tit {
                    padding-top:0 !important;
                    padding-bottom:8px !important;
                    border-bottom-width:2px !important;
                    font-size:16px !important;
                    line-height:22px;
                }
                .info-tit-wrap {
                    border-bottom-width:2px !important;
                    padding-bottom:8px !important;
                    .h3-tit {
                        padding-top:0 !important;
                        padding-bottom:0 !important;
                        font-size:16px !important;
                        line-height:22px;
                    }
                }
                .map {
                    height:178px !important;
                    margin-bottom:16px;
                }
                .accordion-border-wrap {
                    margin-top:24px;
                    > ul {
                        > .lists {
                            border-bottom:0;
                            &:first-child {
                                border-top:0;
                            }
                            &.on {
                                background-color:#FFF;
                            }
                            .head {
                                border-bottom:1px solid #DDD;
                                .accord-btn {
                                    padding:16px 0 14px;
                                    &:after {
                                        right:0;
                                        width:16px;
                                        height:16px;
                                        background:url(/lg5-common/images/icons/btn-down-16-black.svg) no-repeat 0 0;
                                        background-size:16px;
                                        transform:rotate(0deg);
                                    }
                                    .title {
                                        font-size:15px;
                                        line-height:22px;
                                    }
                                }
                            }
                            .accord-cont {
                                padding:16px 0 24px;  
                                .bullet-list {
                                    margin-top:0;
                                    .b-txt {
                                        font-size:13px;
                                        line-height:19px;
                                        &:before {
                                            width:2px;
                                            height:2px;
                                            margin-top:8px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.photo {
                    margin-right:0 !important;
                }
                
            }
        }
        .slide-wrap {
            margin-top:24px !important;
            margin-right:-16px;
            padding-bottom:36px !important;
            .slide_inner {
                width:83.3333vw                
            }
            @media screen and  (min-width: 768px) {
                .slide_inner {
                    width:100%;  
                }      
            }
            .slide-content {
                .slide-conts {
                    .img {
                        height:175px !important;
                    }
                }
            }
            .indi-wrap {
                li{
                    &.on {
                        .btn-indi {
                            &:before {
                                background:#000;
                            }
                        }
                    }
                }
            }
        }
    }
    .toggle-layer {
        .form-infos {
            margin-bottom:32px !important;
        }
        .form-wrap {
            margin-top:32px !important;
        }
        .layer-content {
            padding-bottom:150px !important;
        }
        .btn-group {
            position:fixed;
            bottom:0;
            left:0;
            right:0;
            margin-top:0;
            padding:14px 16px;
            background:#FFF;
            border-top:1px solid #DDD;
        }
    }
}

/* .flag-wrap 재정의 */
.flag-wrap {
    &.bar-type {
        margin-bottom:4px;
        .flag {
            height:auto;
            font-size:11px;
            line-height:1;
        }
    }
    .flag {
        color:#ea1917;
        &.green {
            color:#008182;
        }
    }
}

.service-status-list {
    .list-wrap {
        .options {
            margin-top:6px;
            font-size:11px;
            line-height:16px;
            > li {
                margin-top:4px;
                color:#666;
                &:first-of-type {
                    margin-top:0;
                    display:inline-block;
                    width:100%;
                    &:after {
                        display:none;
                    }
                }
            }
        }
    }
    .service-history {
        margin-top:16px;
        margin-bottom:40px !important;
        .service-box {
            padding:24px 20px;
            border:1px solid #DDD;
            border-radius:8px;
            .service-history-title {
                margin-bottom:0;  
                font-size:16px;  
                line-height:22px;
            }
            .service-history-head {
                display:flex;
                align-items:end;
                justify-content:space-between;
                padding-bottom:12px;
                border-bottom:1px solid #DDD;
            }
            .item {
                display:none;
                margin-top:20px !important;
                padding:0 !important;
                border:0 !important;
                &.active {
                    display:block;
                }
                .service-history-list {
                    display:flex;
                    justify-content:space-between;
                    margin-top:16px;
                    dt {
                        width:100px;
                    }
                    dd {
                        width:calc(100% - 100px);
                        margin-top:0;
                        text-align:right;                        
                        ul {
                            font-size:0;
                            text-align:right;
                            white-space:normal;
                            li {
                                margin-left:10px;
                                padding-left:10px;
                                color:#666;
                                &:first-of-type {
                                    margin-left:0; 
                                    padding-left:0;  
                                }
                            }
                        }
                        .color-3 {
                            font-weight:500;
                        }
                    }
                    &:after {
                        display:block;
                        clear:both;
                        content:'';
                    }
                }
            }
        }
        
    }
}

/* chk-wrap 2.0 재저의 */
.chk-wrap {
    input {
        + label {
            &:after {
                background-image: url(/lg5-common/images/icons/btn-checkbox-off_new.svg);
            }
        }
        &:checked {
            + label {
                &:after {
                    background-image: url(/lg5-common/images/icons/btn-checkbox-on_new.svg);
                }
            }
        }
        &:disabled {
            + label {
                &:after {
                    background-image: url(/lg5-common/images/icons/btn-checkbox-disabled_new.svg);
                }
            }
        }
    }
}

/* agree */
.agree-all-chk {
    + .input-info {
        margin-top:24px;
    }
}
.agree-wrap {
    .agree-tit {
        margin-bottom:12px;
    }    
}
.agree-cont-wrap {
    .agree-cont-box {
        border-radius:6px 6px 0 0;
    }
    .agree-input{
        border-radius:0 0 6px 6px;
    }
}

/* 개선해 주세요 */
.suggestions {
    padding-bottom:85px;
    .step-box {
        .form-infos {
            margin-top:16px;
            margin-bottom:0px;
        }
    }
    .cont-wrap {
        padding-top:0 !important;
        .section{
            .tit-wrap {
                .tit { 
                    margin-top:16px;   
                }
            } 
        } 
    }
    .prod-selected-wrap {
        display:none;
    }
    .login-guide {
        margin-top:16px;
    }
}

/* psp 제품지원 */
.support-psp {
    overflow:hidden;
    //padding-bottom:100px;
    .login-guide {
        margin-top:16px;
    }
    @include mobile{
        padding-bottom:0;
    }
    /* BTOCSITE-1433 - App> 고객지원 Main, pinch out 기능 제공 */
    .slick-slider{
        touch-action: auto;
    }
    /* //BTOCSITE-1433 - App> 고객지원 Main, pinch out 기능 제공 */
    .btn {
        &.light-gray {
            background: #aaa;
            border-color:#aaa;
            color:#fff;
        }
    }

    //공지사항
    .home-notice {
        // BTOCSITE-9066 수정
        overflow:hidden;
        height:77px;
        border-top:1px solid #f4f4f4;
        margin-top: 41px;
        .notice-inner {
            display:table;
            position: relative;
            max-width:850px;
            width:100%;
            margin:0 auto;
            //padding:0 40px;
            table-layout: fixed;
        }

        .notice-title {
            display:table-cell;
            width:70px;
            padding-right:10px;
            vertical-align: middle;
            .title {
                font-size:14px;
                line-height:20px; //BTOCSITE-9066 수정
                color:#000; //BTOCSITE-9066 수정
                font-weight:500;
            }
        }
        .notice-count {
            position: absolute;
            right: 47px;
            top: 26px;
            border-radius:10px;
            height:20px;
            padding:0 12px;
            background:rgba(0,0,0,0.35);
            color:#fff;
            font-size: 10px;
            line-height:20px;
        }
        .notice-list-wrap {
            display:table-cell;
            position: relative;
            width:100%;
            //padding:0 84px 0 16px;
            padding:0 106px 0 16px;
            font-size:16px;
            line-height: 24px;
            box-sizing:border-box;
            vertical-align: middle;
            opacity:0;
            transition:opacity .3s;
            color:#666; //BTOCSITE-9066 추가
            &.slick-initialized {
                opacity:1;
            }
            &:before {
                content:"";
                display:block;
                position: absolute;
                top:50%;
                left:0;
                transform:translateY(-50%);
                width:1px;
                height:12px;
                background: #ddd;
            }
            .flag-wrap {
                margin-right:6px;
                .flag {
                    font-size:12px;
                    line-height:1;
                    height:auto;
                }
            }
            .notice-list {
                height:71px;
                line-height:71px;
                display: flex;
                justify-content: flex-start;
                a{
                    @include textEllipsis;
                }
            }
            .slide-contents {
                overflow:hidden;
                height:70px;
            }
        }
        .slick-arrow {
            position: absolute;
            width:28px;
            height:28px;
            padding:6px;
            top:50%;
            transform:translateY(-50%);
            background-size: 24px 24px;
            background-position: center center;
            background-repeat:no-repeat;

            &:before {
                display: none;
            }

            &.slick-prev {
                left:auto;
                right:56px;
                background-image: url('/lg5-common/images/CS/icon-notice-up-24.svg');
            }
            &.slick-next {
                right:28px;
                background-image: url('/lg5-common/images/CS/icon-notice-down-24.svg');
            }
        }

        .btn-play {
            display: block;
            position: absolute;
            width:28px;
            height:28px;
            top:50%;
            right:0;
            transform:translateY(-50%);
            background-size: 20px 20px;
            background-position: center center;
            background-repeat:no-repeat;
            background-image: url('/lg5-common/images/icons/btn-pause-16-gray.svg');

            &.pause {
                background-image: url('/lg5-common/images/icons/btn-play-16-gray.svg');
            }
        }
        @include tablet {
            .notice-inner { 
               max-width: 100%;
               padding:0 10px;
            }

            .notice-count {
                top:26px;
            }

            .btn-play {
                right: 10px;
            }
        }
        @include mobile {
            .notice-inner {
                display:block;
                padding:12px 24px 18px;
            }

            .notice-title {
                display: block;
                .title {
                    font-size:14px;
                    line-height:1.63;
                }
            }
            .notice-count {
                top:14px;
            }
            .notice-list-wrap {
                display: block;
                margin-left:0;
                padding:0;
                font-size:13px;
                &:before {
                    display: none;
                }

                .flag-wrap {
                    .flag {
                        font-size:11px;
                    }
                }

                .notice-list {
                    height:40px;
                    line-height:31px;
                }

                .slide-contents {
                    height:36px;
                }
            }

            .slick-arrow {
                top:-25px;
                transform:none;
                &.slick-prev {
                    background-size:24px 24px;
                }
                &.slick-next {
                    background-size:24px 24px;
                }
            }
            .btn-play {
                top:10px;
                right:15px;
                transform:none;
                background-size:20px 20px;
                padding:6px;
            }
        }
    }

    //검색영역
    .keyword-search {
        margin:48px 0;
        padding:0 40px;
        @include mobile{
            margin:19px -4px 7px;
        }
        .search-box {
            max-width:850px;
            width:100%;

            .btn-search {
                right:10px;
                height:66px;
                background: center/24px 24px url(/lg5-common/images/icons/icon-search-32.svg) no-repeat;
            }
            .btn-delete {
                right:70px;
            }
        }
        .search-input {
            width:100%;
            input[type=text]  {
                padding:18px 64px 18px 32px;
                border-radius:33px;
                background:#f4f5f7;
                box-shadow: none;
            }
            &.clear {
                input[type=text]  {
                    padding-right:90px;
                }   
            }
        }
        .autocomplete-box {
            .keyword-list {
                a {
                    display:block;
                    max-width:none;
                    @include clearfix;
                    .model {
                        float:left;
                    }
                    .category {
                        float:right;
                    }  
                }
            }
        }

        @include mobile {
            padding:0;
            .search-box {
                .btn-search {
                    right:17px;
                    height:46px;
                    width:34px;
                }
                .btn-delete {
                    right:60px ;
                }
            }
            .search-input {
                input[type=text]  {
                    padding:12px 41px 12px 20px;
                }
                &.clear {
                    input[type=text]  {
                        padding-right:97px;
                    }   
                }
            }
            .autocomplete-box {
                .keyword-list {
                    a {
                        .category {
                            display:none;
                        }
                    }
                }
            }
        }
    }

    /* 보유제품 */
    .product-header {
        margin:16px 16px 0;
        .product-header-inner {
            display:flex;
            align-items:start;
            padding:16px 16px 20px;
            border:1px solid #DDD;
            border-radius:8px;
            .visual {
                margin:-3px 0;
                width:66px;
                .img-wrap {
                    width:66px;
                    height:66px;
                    img {
                        width:100%;
                        height:100%;
                    }
                }
            } 
            .conts {
                margin-left:12px;
                .flag-wrap {                    
                    margin-bottom:3px;
                    .flag {
                        height:auto;
                        font-weight:normal;
                        font-size:11px;
                        line-height:16px;
                    }
                }
                .tit {
                    font-size:15px;
                    line-height:22px;
                }
                .desc {
                    margin-top:4px;
                    font-size:0;
                    color:#666;
                    .cate, .model {
                        font-size:11px;
                        line-height:16px;
                    }
                    .model {
                        &:before {
                            display:inline-block;
                            margin:0 6px;
                            width:1px;
                            height:10px;
                            background:#666;
                            content:'';
                        }
                    }
                }
                .btn-wrap {
                    margin-top:8px;
                }
            }
        }
    }
    // 상단 로그인 툴팁 박스
    .tooltip {
        &-login {
            display: block;
            max-width:100%;
            margin:0 auto;
            background-color: #DA0F47; //BTOCSITE-50181 상단 띠배너 추가] 이브닝 서비스 홍보 활동 지속 // BTOCSITE-14407 WSG 수정 : 그라데이션 사용 제거 건
            // BTOCSITE-24154 [로그인유도] 고객지원 홈 띠배너 (Hello Bar) 노출을 모바일 웹/앱으로 확대
            // @include mobile {
            //     display:none; //0625
            // }
        }
        &-inner {
            position: relative;
            max-width:1380px;
            margin:0 auto;
            padding:17px 0;
            text-align: center;
            p {
                display: inline-block;
                color:#fff;
                font-size:16px;
                line-height:26px;
                vertical-align: middle;
                strong {
                    font-weight:bold;
                }
            }

            .btn-link {
                margin-left:16px;
                color:#fff;
                font-weight:bold;
                text-decoration: none;

                &:after {
                    width:16px;
                    height:16px;
                    background-image: url("/lg5-common/images/icons/btn-arr-16-white.svg");
                    background-size:100% 100%;
                }
                
            }
            .btn-tooltip-close {
                position: absolute;
                top:50%;
                right:0;
                transform:translateY(-50%);
                width:48px;
                height:48px;
                background: url("/lg5-common/images/icons/btn-close-24-white.svg") no-repeat center center/24px 24px;
            }
            @include mobile {
                text-align: left;
                padding:10px 16px; // BTOCSITE-24154 [로그인유도] 고객지원 홈 띠배너 (Hello Bar) 노출을 모바일 웹/앱으로 확대
                p {
                    display: block;
                    font-size:13px;
                }
                .btn-link {
                    margin-left:0;
                }
            }
        }
    }
}
.support.support-psp {
    // BTOCSITE-50795 제품 지원 페이지] 연관 소모품 노출 강화
    // PSP 관련 소모품이 필요하신가요?
    .common-banner {
        margin-top: 44px;
        padding-top: 0;
        border-top: 0;
        &:last-of-type {
            margin-bottom: 44px;
        }
        .banner-tit {
            font-size: 18px !important;
            line-height: 24px !important;
        }
        .product-slider {
            .slide-track {
                display: flex;
            }
        }
    }
}
.home-links {
    .count {
        position: absolute;
        top:0;
        right:0;
        z-index:11;
        width:18px;
        height:18px;
        font-weight:700;
        font-size:11px;
        line-height:18px; 
        color:#FFF;
        text-align:center;
        border-radius:50%;
        background-color:#ea1917;
    }
}

// BTOCSITE-67844 고객지원 > 메인 > 베스트케어 구좌 추가 요청
.bestcare-section {
    max-width: 1024px;
    margin: 0 auto;
    padding: 0 16px 16px;
    .bestcare-header {
        margin-bottom: 20px;
        padding-right: 55px;
        .bestcare-title {
            font-size: 20px;
            line-height: 26px;
            font-weight: 700;
            color: #000;
        }
        .bestcare-description {
            margin-top: 4px;
            font-size: 15px;
            line-height: 21px;
            color: #000;
        }
    }
    .bestcare-content {
        .bestcare{
            &-list {
                display: flex;
                flex-wrap: wrap;
            }
            &-banner {
                width: 100%;
                margin-bottom: 12px;
                margin-right: 0;
                border-radius: 8px;
                background: #f6f6f6;
            }
            &-link {
                display: block;
                position: relative;
                height: 110px;
                padding: 20px 96px 20px 16px;
                &--campaign {
                    padding-right: 136px;
                }
            }
            &-text-wrap {
                .bestcare-desc {
                    color: #666;
                    font-size: 14px;
                    line-height: 20px;
                }
                .bestcare-title {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;
                    &--bold {
                        font-weight:700;
                    }
                }
                .bestcare-desc + .bestcare-title {
                    margin-top: 2px;
                }
            }
            // [BTOCSITE-76716] [UI]LGE.COM 홈 > 베스트케어 이벤트 구좌 추가 요청
            &-text-link {
                display: inline-flex;
                align-items: center;
                margin-top: 8px;
                p {
                    font-size: 11px;
                    line-height: 15px;
                    color: #666;
                }
                &:after {
                    content: "";
                    display: inline-block;
                    margin-left: 2px;
                    margin-top: 2px;
                    width: 12px;
                    height: 12px;
                    background: url('/lg5-common/images/MA/mainRenewal/icon-arrow-right-16.svg') center no-repeat;
                    background-size: 12px 12px;
                    opacity: 0.7;
                }
            }
            &-image {
                position: absolute;
                img {
                    width:100%;
                    height:100%;
                }
                &.bestcare-image--clean {
                    bottom: 15px;
                    right: 16px;
                    width: 80px;
                    height: 80px;
                }
                &.bestcare-image--install {
                    bottom: 24px;
                    right: 16px;
                    width: 80px;
                    height: 54px;
                }
                &.bestcare-image--campaign {
                    bottom: 0;
                    right: 0;
                    width:110px;
                    height:110px;
                }
            }
        }
    }
}