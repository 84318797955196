@charset "UTF-8";

.pay-warranty {
    @at-root &.pop-conts .tabs-contents,
    .tabs-contents {
        padding-top:48px;
        @include mobile {
            padding-top:40px;
        }
    }
    .scroll-x {
        margin-bottom: 17px;
        overflow-x: auto;
        &:last-child {
            margin-bottom: 0;
        }
        table {
            min-width: 720px;
            width: calc(100% - 1px);
        }

        @include mobile {
            margin-bottom:12px;

            &:last-child {
                margin-bottom:0;
            }
        }
    }
    .tooltip-wrap {
        &.share {
            position: absolute;
            top: 40px;
            right: 0;
            @include mobile {
                top: 32px;
                right: 16px;
            }
        }
    }
    .section-area {
        position: relative;
        .section {
            padding:0;
            @include mobile {
                padding:0 16px;
                border-radius:0;
            }
            & + .section {
                margin-top:80px;
                @include mobile {
                    border-top:0;
                    margin-top:12px;
                }
            }
        }
    }
    .paragraph {
        &-1 {
            margin-top: 12px;
            margin-bottom: 12px;
        }
        &-2 {
            margin-bottom: 22px;
        }
        &-3 {
            margin-top: 8px;
        }
        &-4 {
            // margin-top: 16px;
        }
        &-5 {
            margin-bottom: 16px;
        }
        &-6 {
            font-size: 14px;
        }
        &-7 {
            font-size: 14px;
            color: #666;
        }
    }

    .support-bullet {
        &-title {
            margin-bottom: 30px;
        }
        &-list {
            margin-top: 0;
        }
    }
  
    [class^="h-tit"] {
        word-break: keep-all;
        &[class*="-3"] {
            font-size: 20px;
            font-weight: bold;
            line-height: 1.42;
            margin-bottom: 24px;
            @include mobile {
                margin-bottom: 12px;
            }
        }
        &[class*="-4"] {
            font-size: 18px;
            line-height: 1.5;
            margin-bottom: 16px;
            font-weight: bold;
        }
        &[class*="-subtitle"] {
            font-size: 16px;
            font-weight:bold;
            margin-top:21px;
            margin-bottom: 22px;
            line-height:1.63;
            @include mobile {
                margin-top: 23px;
                margin-bottom: 16px;
            }
        }
    }

    .dl-list {
        margin-top: 30px;
        line-height: 1.38;
        
        .dscl {
            padding:28px 0;
            font-size:14px;
            color:#666;
        }
      
        dt {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.44;
            margin-bottom: 16px;

            
        }
        dd {
            font-size: 16px;
            margin-bottom: 34px;
            line-height:1.63;
            &:last-child {
                margin-bottom: 0;
            }
            @include mobile {
                margin-bottom: 24px;
            }
        }
    }
    
    .img_box {
        margin-top: 24px;
        &.pc-pop-only {
            display: none;
        }
        img {
            max-width: 100%;
            width:100%;
        }
    }
    
    .list {
        li {
            &:first-child {
                margin-top: 0;
            }
        }
        > li {
            margin-top: 2px;
            margin-left: 23px;
            text-indent: -1.5em;
            > ul:not(.bullet-list) {
                > li {
                    margin-left: 24px;
                    text-indent: -24px;
                    > ul {
                        > li {
                            text-indent: 0;
                        }
                    }
                }
                &.bullet-list {
                    li {
                        text-indent: 0;
                    }
                }
            }
            > ul {
                margin-top: 5px;
                 li {
                    margin-top: 5px;
                }
            }
            > .bullet-list {
                text-indent: 0;
            }
        }
        @include mobile {
            > li {
                margin-left: 16px;
                text-indent: 0;
                > ul:not(.bullet-list) {
                    > li {
                        margin-left: 40px;
                        text-indent: -24px;
                    }
                }
                > .bullet-list {
                    > li {
                        margin-left: 16px;
                        text-indent: 0;
                    }
                }
            }
        }
    }

    .text-space-1 {
        padding-left: 4.3em;
        @include mobile {
            padding-left: 0;
        }
    }

    .support-bullet {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.38;
        color: #000;
        position: relative;
        padding-left: 12px;
        margin-bottom: 16px;
        &::after {
            position: absolute;
            left: 0;
            top: 9px;
            content: '';
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 2px;
            background-color: #616161;
        }
    }

    .flex-p {
        display: flex;
        align-items: center;
        .btn-link {
            margin-left: 24px;
        }
    }

    .bullet-list {
        font-size: 16px;
        margin-top: 0;
    }

    .tb_row {
        &.tb-row-bl {
            table {
                tr {
                    th,
                    td {
                        border-left: 1px solid #ddd;
                        &:first-child {
                            border-left: none;
                        }
                    }
                }
            }
        }
    }

    .tb-col {
        th {
            vertical-align: middle;
        }
    }

    .note-box {
        background-color: #f4f4f4;
        padding: 24px 40px;
        font-size: 14px;

        .in-note-box {
            margin-top: 2px;
            font-size: 14px;
            margin-bottom: 8px;
        }
        @include mobile {
            padding: 20px;
        }
    }
    sup {
        font-size: 0.625em;
    }

    .pop-only {
        display: none;
    }
}

#ratesWarrantyGuidePopup {
    max-width: 912px;
    .pay-warranty {
        .img_box {
            &.pc-only {
                display: none;
            }
            &.pc-pop-only {
                display: block;
            }
        }
    }
    @include mobile {
        .pay-warranty {
            .section-area {
                .section {
                    padding:0;
                }
            }
            .img_box {
                &.pc-pop-only {
                    display: none;
                }
            }
        }
    }
}